import axios from 'axios';

const media = {
  WILL_GET_IMAGES: 'media/WILL_GET_IMAGES',
  DID_GET_IMAGES: 'media/DID_GET_IMAGES',
  FAILED_TO_GET_IMAGES: 'media/FAILED_TO_GET_IMAGES',

  WILL_UPLOAD_IMAGES: 'media/WILL_UPLOAD_IMAGES',
  DID_UPLOAD_IMAGES: 'media/DID_UPLOAD_IMAGES',
  FAILED_TO_UPLOAD_IMAGES: 'media/FAILED_TO_UPLOAD_IMAGES',

  WILL_REMOVE_IMAGE: 'media/WILL_REMOVE_IMAGE',
  DID_REMOVE_IMAGE: 'media/DID_REMOVE_IMAGE',
  FAILED_TO_REMOVE_IMAGE: 'media/FAILED_TO_REMOVE_IMAGE',

  WILL_GET_VIDEOS: 'media/WILL_GET_VIDEOS',
  DID_GET_VIDEOS: 'media/DID_GET_VIDEOS',
  FAILED_TO_GET_VIDEOS: 'media/FAILED_TO_GET_VIDEOS',

  WILL_ADD_VIDEO: 'media/WILL_ADD_VIDEO',
  DID_ADD_VIDEO: 'media/DID_ADD_VIDEO',
  FAILED_TO_ADD_VIDEO: 'media/FAILED_TO_ADD_VIDEO',

  WILL_REMOVE_VIDEO: 'media/WILL_REMOVE_VIDEO',
  DID_REMOVE_VIDEO: 'media/DID_REMOVE_VIDEO',
  FAILED_TO_REMOVE_VIDEO: 'media/FAILED_TO_REMOVE_VIDEO',

  WILL_GET_LOGOS: 'media/WILL_GET_LOGOS',
  DID_GET_LOGOS: 'media/DID_GET_LOGOS',
  FAILED_TO_GET_LOGOS: 'media/FAILED_TO_GET_LOGOS',

  WILL_UPLOAD_LOGOS: 'media/WILL_UPLOAD_LOGOS',
  DID_UPLOAD_LOGOS: 'media/DID_UPLOAD_LOGOS',
  FAILED_TO_UPLOAD_LOGOS: 'media/FAILED_TO_UPLOAD_LOGOS',

  WILL_REMOVE_LOGO: 'media/WILL_REMOVE_LOGO',
  DID_REMOVE_LOGO: 'media/DID_REMOVE_LOGO',
  FAILED_TO_REMOVE_LOGO: 'media/FAILED_TO_REMOVE_LOGO',

  WILL_GET_SOUNDS: 'media/WILL_GET_SOUNDS',
  DID_GET_SOUNDS: 'media/DID_GET_SOUNDS',
  FAILED_TO_GET_SOUNDS: 'media/FAILED_TO_GET_SOUNDS',

  WILL_ADD_SOUND: 'media/WILL_ADD_SOUND',
  DID_ADD_SOUND: 'media/DID_ADD_SOUND',
  FAILED_TO_ADD_SOUND: 'media/FAILED_TO_ADD_SOUND',

  WILL_REMOVE_SOUND: 'media/WILL_REMOVE_SOUND',
  DID_REMOVE_SOUND: 'media/DID_REMOVE_SOUND',
  FAILED_TO_REMOVE_SOUND: 'media/FAILED_TO_REMOVE_SOUND',

  DID_GET_MEDIA: 'media/DID_GET_MEDIA',
};

export default media;

// Get all media
export const getMedia = () => dispatch => {
  dispatch(getImages());
  dispatch(getVideos());
  dispatch(getLogos());
  dispatch({
    type: media.DID_GET_MEDIA,
  });
};

// Get all images
export const getImages = () => dispatch => {
  dispatch({
    type: media.WILL_GET_IMAGES,
  });

  axios
    .get('/api/media/images')
    .then(res => {
      dispatch({
        type: media.DID_GET_IMAGES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: media.FAILED_TO_GET_IMAGES,
        payload: err,
      });
    });
};

export const uploadImages = images => dispatch => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const data = new FormData();

  for (let index = 0; index < images.files.length; index++) {
    data.append('file', images.files[index]);
  }

  if (images.credits.trim()) {
    data.append('credits', images.credits);
  }

  dispatch({ type: media.WILL_UPLOAD_IMAGES });

  axios
    .post('/api/media/images', data, config)
    .then(res => {
      dispatch({
        type: media.DID_UPLOAD_IMAGES,
        payload: res.data,
      });
    })
    .then(() => {
      dispatch(getImages());
    })
    .catch(err => {
      dispatch({
        type: media.FAILED_TO_UPLOAD_IMAGES,
        payload: err,
      });
    });
};

export const deleteImage = id => dispatch => {
  dispatch({
    type: media.WILL_REMOVE_IMAGE,
  });

  axios
    .delete(`/api/media/images/${id}`)
    .then(res => {
      dispatch({
        type: media.DID_REMOVE_IMAGE,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: media.FAILED_TO_REMOVE_IMAGE,
        payload: err,
      });
    });
};

// Get all logos
export const getLogos = () => dispatch => {
  dispatch({
    type: media.WILL_GET_LOGOS,
  });

  axios
    .get('/api/media/logos')
    .then(res => {
      dispatch({
        type: media.DID_GET_LOGOS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: media.FAILED_TO_GET_LOGOS,
        payload: err,
      });
    });
};

export const uploadLogos = logos => dispatch => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const data = new FormData();

  for (let index = 0; index < logos.files.length; index++) {
    data.append('file', logos.files[index]);
  }

  dispatch({ type: media.WILL_UPLOAD_LOGOS });

  axios
    .post('/api/media/logos', data, config)
    .then(res => {
      dispatch({
        type: media.DID_UPLOAD_LOGOS,
        payload: res.data,
      });
    })
    .then(() => {
      dispatch(getLogos());
    })
    .catch(err => {
      dispatch({
        type: media.FAILED_TO_UPLOAD_LOGOS,
        payload: err,
      });
    });
};

export const deleteLogo = id => dispatch => {
  dispatch({
    type: media.WILL_REMOVE_LOGO,
  });

  axios
    .delete(`/api/media/logos/${id}`)
    .then(res => {
      dispatch({
        type: media.DID_REMOVE_LOGO,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: media.FAILED_TO_REMOVE_LOGO,
        payload: err,
      });
    });
};

// Get all videos
export const getVideos = () => dispatch => {
  dispatch({
    type: media.WILL_GET_VIDEOS,
  });

  axios
    .get('/api/media/videos')
    .then(res => {
      dispatch({
        type: media.DID_GET_VIDEOS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: media.FAILED_TO_GET_VIDEOS,
        payload: err,
      });
    });
};

export const addVideo = videoLink => dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ videoLink });

  dispatch({ type: media.WILL_ADD_VIDEO });

  axios
    .post('/api/media/videos', body, config)
    .then(res => {
      dispatch({
        type: media.DID_ADD_VIDEO,
        payload: res.data,
      });
    })
    .then(() => {
      dispatch(getVideos());
    })
    .catch(err => {
      dispatch({
        type: media.FAILED_TO_ADD_VIDEO,
        payload: err,
      });
    });
};

export const deleteVideo = id => dispatch => {
  dispatch({
    type: media.WILL_REMOVE_VIDEO,
  });

  axios
    .delete(`/api/media/videos/${id}`)
    .then(res => {
      dispatch({
        type: media.DID_REMOVE_VIDEO,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: media.FAILED_TO_REMOVE_VIDEO,
        payload: err,
      });
    });
};

// Get all sounds
export const getSounds = () => dispatch => {
  dispatch({
    type: media.WILL_GET_SOUNDS,
  });

  axios
    .get('/api/media/sounds')
    .then(res => {
      dispatch({
        type: media.DID_GET_SOUNDS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: media.FAILED_TO_GET_SOUNDS,
        payload: err,
      });
    });
};

export const addSound = soundLink => dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ soundLink });

  dispatch({ type: media.WILL_ADD_SOUND });

  axios
    .post('/api/media/sounds', body, config)
    .then(res => {
      dispatch({
        type: media.DID_ADD_SOUND,
        payload: res.data,
      });
    })
    .then(() => {
      dispatch(getSounds());
    })
    .catch(err => {
      dispatch({
        type: media.FAILED_TO_ADD_SOUND,
        payload: err,
      });
    });
};

export const deleteSound = id => dispatch => {
  dispatch({
    type: media.WILL_REMOVE_SOUND,
  });

  axios
    .delete(`/api/media/sounds/${id}`)
    .then(res => {
      dispatch({
        type: media.DID_REMOVE_SOUND,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: media.FAILED_TO_REMOVE_SOUND,
        payload: err,
      });
    });
};
