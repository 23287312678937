import { combineReducers } from 'redux';
import auth from './auth';
import carousel from './carousel';
import welcome from './welcome';
import contact from './contact';
import events from './events';
import media from './media';
import press from './press';

export default combineReducers({
  auth,
  carousel,
  welcome,
  contact,
  events,
  media,
  press,
});
