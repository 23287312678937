import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#686d76',
    },
    secondary: {
      main: '#eeeeee',
    },
    text: {
      primary: '#686d76',
      secondary: '#fff'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ['Raleway', 'sans-serif'].join(','),
  },
});

export default theme;
