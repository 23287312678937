import { Container, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Title from 'src/shared/AnimatedTitle';
import Logos from 'src/shared/Footer/Logos';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '10rem',
    marginBottom: '10rem',
  },
}));

const Supporters = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Title subHeader={t('footer.supporters')} />
      <Logos />
    </Container>
  );
};

export default Supporters;
