import { Grid, Link, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  links: {
    color: theme.palette.text.secondary,
    '&:hover': {
      textDecoration: 'none',
      color: '#fff',
    },
  },
  linkContainer: {
    width: '100%',
  },
}));

export default function ToolBar() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      className={classes.linkContainer}
      direction={matches ? 'row' : 'column'}
      spacing={2}
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <Typography variant="h6">
          <Link
            underline="none"
            className={classes.links}
            component={RouterLink}
            to="/dashboard/homePage"
          >
            Home
          </Link>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6">
          <Link
            underline="none"
            className={classes.links}
            component={RouterLink}
            to="/dashboard/events"
          >
            Events
          </Link>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6">
          <Link
            underline="none"
            className={classes.links}
            component={RouterLink}
            to="/dashboard/media"
          >
            Media
          </Link>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6">
          <Link
            underline="none"
            className={classes.links}
            component={RouterLink}
            to="/dashboard/press"
          >
            Press
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}
