import { Container, Grid, Paper, Typography } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import { RiAddFill as AddIcon } from 'react-icons/ri';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getEvents } from '../../../actions/events';
import AddModal from '../../../shared/AddModal';
import AddEvent from './AddEvent';
import EventInfo from './EventInfo';
import LoadingSpinner from 'src/shared/LoadingSpinner';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10rem',
  },
  paper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 'calc(5% + 64px)',
  },
  header: {
    marginBottom: '50px',
    color: '#000',
  },
  eventCard: {
    padding: '15px',
  },
  fabIcon: {
    color: '#fff',
    backgroundColor: theme.palette.primary,
    position: 'absolute',
    right: '20px',
    bottom: '20px',
    '&:hover': {
      opacity: '0.9',
    },
  },
  modal: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Events = ({ eventList, getEvents, loading }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getEvents();
  }, [getEvents]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading)
    return (
      <Container component="main" className={classes.root}>
        <LoadingSpinner />;
      </Container>
    );
  
  
  const orderedEventListByDate = eventList?.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  return (
    <Container component="main" className={classes.root}>
      <Typography
        color="secondary"
        align="center"
        className={classes.header}
        gutterBottom
        component="h1"
        variant="h4"
      >
        Your Events
      </Typography>

      {(!orderedEventListByDate || orderedEventListByDate.length === 0) && (
        <Typography color="secondary" component="h1" variant="body2">
          You have no events yet
        </Typography>
      )}
      <Grid container spacing={3}>
        {orderedEventListByDate?.map(event => (
          <Grid key={event._id} item xs={12} md={6}>
            <Paper className={classes.eventCard}>
              <EventInfo
                key={event._id}
                eventId={event._id}
                eventName={event.name}
                eventDate={event.date}
                eventAddress={event.address}
                eventMembers={event.members}
                eventInfoLink={event.infoLink}
                eventLocale={event.locale || 'en'}
              />
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Fab
        onClick={handleOpen}
        className={classes.fabIcon}
        color="primary"
        aria-label="add"
      >
        <AddIcon size={25} />
      </Fab>
      <AddModal isOpen={open} close={handleClose}>
        <Typography
          color="primary"
          className={classes.header}
          component="h1"
          variant="h4"
          align="center"
        >
          New Event
        </Typography>
        <AddEvent />
      </AddModal>
    </Container>
  );
};

const mapStateToProps = state => ({
  eventList: state.events.eventsList,
  loading: state.events.loading,
});

const mapDispatchToProps = {
  getEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
