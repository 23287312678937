import {
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdSend as SendIcon } from 'react-icons/md';
import { connect } from 'react-redux';

import { sendMessage } from '../../actions/contact';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    marginTop: '5rem',
    minHeight: '85%',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  title: {
    fontSize: '22px',
    fontWeight: 'bold'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  input: {
    color: theme.palette.text.primary,
    backgroundColor: '#fff',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.text.primary,
    color: '#fff'
  },
  success: {
    color: 'green',
  },
  error: {
    color: theme.palette.error,
    backgroundColor: theme.palette.error,
  },
}));

const Contact = ({ sendMessage, sending, status }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [formError, setFormError] = useState();

  const { name, message, email } = formData;

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();
    if (!name || !message || !email) {
      setFormError('All fields are required');
    } else {
      sendMessage(name, email, message);
    }
  };

  const renderStatus = status => {
    if (status === undefined || !status[0].code) {
      return (
        <Typography
          align="center"
          variant="subtitle1"
          className={classes.error}
        >
          {status[0].msg}
        </Typography>
      );
    }

    return (
      <Typography
        align="center"
        variant="subtitle1"
        className={classes.success}
      >
        {status[0].msg}
      </Typography>
    );
  };

  return (
    <span>
      <Container maxWidth="lg" className={classes.root}>
        <div className={classes.paper}>
          <Typography color="textPrimary" className={classes.title} gutterBottom>
            {t('contact.title')}
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              required
              InputProps={{ className: classes.input }}
              InputLabelProps={{ className: classes.input }}
              variant="outlined"
              margin="normal"
              fullWidth
              label={t('contact.name')}
              name="name"
              value={name}
              onChange={onChange}
            />
            <TextField
              required
              InputLabelProps={{ className: classes.input }}
              InputProps={{ className: classes.input }}
              variant="outlined"
              margin="normal"
              fullWidth
              name="email"
              label={t('contact.email')}
              type="email"
              value={email}
              onChange={onChange}
            />
            <TextField
              required
              InputLabelProps={{ className: classes.input }}
              InputProps={{ className: classes.input }}
              variant="outlined"
              margin="normal"
              fullWidth
              name="message"
              label={t('contact.message')}
              type="text"
              value={message}
              onChange={onChange}
              multiline
              rows={20}
              rowsMax={10}
            />
            {status && status[0].code && renderStatus(status)}
            {status && !status[0].code && (
              <Typography align="center" variant="subtitle1" color="error">
                {status[0].msg}
              </Typography>
            )}
            {formError && !status && (
              <Typography align="center" variant="subtitle1" color="error">
                {formError}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              onClick={onSubmit}
              variant="contained"
              className={classes.submit}
              endIcon={<SendIcon size={25} />}
            >
              {sending ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                <Typography align="center" variant="body1">
                  {t('contact.send')}
                </Typography>
              )}
            </Button>
          </form>
        </div>
      </Container>
    </span>
  );
};

const mapStateToProps = state => ({
  sending: state.contact.sending,
  status: state.contact.status,
});

const mapDispatchToProps = {
  sendMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
