const INFO = {
  EMAIL: 'orosensemble@gmail.com',
  FACEBOOK: 'https://www.facebook.com/orosensemble',
  INSTA:
    'https://www.instagram.com/OrosEnsemble/?fbclid=IwAR0XFg-bcyTH58hNnMTxCkaIk7xte62F5Iv8I_lMOR9yb8GVWFuxEHyIfzw',
  SOUND_CLOUD:
    'https://m.soundcloud.com/user-432203765',
  YOUTUBE: 'https://www.youtube.com/channel/UCZgXs2qGwOzJ-e2CMK3VhTg/featured',
  SPOTIFY: 'https://open.spotify.com/artist/1iLc87Xo1ON2CxCMaqazOa',
  APPLE_MUSIC: 'https://music.apple.com/de/album/eclipse-ep/1715391728?l=en-GB',
  NATIONAL_OPERA: 'https://www.nationalopera.gr/en/',
  VAMVAKOU: 'https://www.vamvakourevival.org/',
  NIARXOS: 'https://www.snf.org/',
};

export default INFO;
