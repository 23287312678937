import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginTop: '10rem',
    marginBottom: '10rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '15rem 0',
    },
  },
  imageContainer: {
    display: 'flex',
    flex: 1,
  },
  textContainer: {
    border: `5px solid ${theme.palette.text.primary}`,
    backgroundColor: '#000',
    display: 'flex',
    flex: 1,
    padding: '3rem',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
  },
  quoteText: {
    color: 'grey',
    fontWeight: 'bold',
    letterSpacing: 0.1235,
    lineHeight: '30px',
    fontSize: '15px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  authorText: {
    color: 'grey',
    fontWeight: 'bold',
    marginTop: '5rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      marginTop: '2rem',
    },
  },
}));

export default function Quote() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container maxWidth={'lg'} className={classes.container}>
      <div className={classes.imageContainer}>
        <img
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          alt="Koumentakis"
          src="https://www.thegreekfoundation.com/wp-content/uploads/2015/09/maxresdefault3.jpg"
        />
      </div>
      <div className={classes.textContainer}>
        <Typography
          color="primary"
          variant="caption"
          align="center"
          gutterBottom
          style={{
            fontStyle: 'italic',
          }}
          className={classes.quoteText}
        >
          {t('homePage.quote')}
        </Typography>
        <Typography
          variant="caption"
          align="center"
          className={classes.authorText}
        >
          {t('homePage.quoteAuthor')}
        </Typography>
      </div>
    </Container>
  );
}
