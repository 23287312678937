import { Divider, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useLocation } from 'react-router-dom';

import ContactSection from './ContactSection';
import SocialSection from './SocialSection';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.secondary.main,
  },
  mainFooterContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '2rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      padding: 0,
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      padding: '2rem 5rem',
      justifyContent: 'space-around',
    },
  },
  credits: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  link: {
    '&:hover': {
      color: '#fff',
    },
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    margin: '2rem',
  },
}));

const Footer = () => {
  const classes = useStyles();
  const location = useLocation();

  if (location.pathname.indexOf('dashboard') !== -1) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Hidden smDown>
        <div className={classes.mainFooterContainer}>
          <ContactSection />
          <SocialSection />
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.mainFooterContainer}>
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            <ContactSection />
            <SocialSection />
          </div>
        </div>
      </Hidden>
      <Divider variant="fullWidth" style={{width: '90%', alignSelf:'center'}} />
      <div className={classes.credits}>
        <Typography variant="body2" color="textPrimary" align="center">
          Copyright © OROS ENSEMBLE | Developed by TASKRIK
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
