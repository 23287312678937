import {
  Backdrop,
  Card,
  CardContent,
  Fade,
  Grid,
  Modal,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { RiCloseLine as CloseIcon } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  memberCardContainer: {
    display: 'block',
    overflow: 'auto',
    msOverflowStyle: 'auto',
    width: '100%',
    maxWidth: '720px',
    maxHeight: '100%',
    margin: 'auto',
    padding: '24px 48px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '24px .5rem',
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: '100%',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  closeIcon: {
    height: '30px',
    width: '30px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  text: {
    [theme.breakpoints.up('xl')]: {
      fontSize: '22px',
    },
  },
  textBio: {
    whiteSpace: 'break-spaces',
    marginTop: '2rem',
    padding: 0,
  },
}));

const MemberCard = ({ member, onClose, isOpen }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen} timeout={{ enter: 1000, exit: 700 }}>
        <Grid
          xs={12}
          item
          className={classes.cardContainer}
          d="transition-modal-title"
        >
          <Card className={classes.memberCardContainer} elevation={10}>
            <div className={classes.closeIconContainer}>
              <CloseIcon
                color="secondary"
                onClick={onClose}
                className={classes.closeIcon}
              />
            </div>
            <div
              style={{
                width: '150px',
                height: '150px',
                margin: 'auto',
                alignSelf: 'center',
                borderRadius: '100px',
                backgroundImage: `url(${member.photo})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
            <CardContent>
              <Typography
                className={classes.text}
                color="primary"
                align="center"
                gutterBottom
                variant="h5"
              >
                {t(`aboutUs.members.${member.name}.name`)}
              </Typography>
              <Typography className={classes.text} align="center" variant="h6">
                {t(`aboutUs.members.${member.name}.role`)}
              </Typography>
              <Typography
                className={classes.textBio}
                variant="body1"
                align="center"
                component="p"
              >
                {t(`aboutUs.members.${member.name}.bio`)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default MemberCard;
