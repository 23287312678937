import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import NavLinks from './navLinks';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '60px',
    backgroundColor: '#343a40',
  },
}));

const SideDrawer = ({ isOpen, toggleDrawer }) => {
  const classes = useStyles();

  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
      <div className={classes.container}>
        <NavLinks onClose={toggleDrawer} alignment="column" />
      </div>
    </Drawer>
  );
};

export default SideDrawer;
