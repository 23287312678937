import axios from 'axios';

const event = {
  WILL_GET_EVENTS: 'event/WILL_GET_EVENTS',
  DID_GET_EVENTS: 'event/DID_GET_EVENTS',
  FAILED_TO_GET_EVENTS: 'event/FAILED_TO_GET_EVENTS',

  WILL_ADD_EVENT: 'event/WILL_ADD_EVENT',
  DID_ADD_EVENT: 'event/DID_ADD_EVENT',
  FAILED_TO_ADD_EVENT: 'event/FAILED_TO_ADD_EVENT',

  WILL_REMOVE_EVENT: 'event/WILL_REMOVE_EVENT',
  DID_REMOVE_EVENT: 'event/DID_REMOVE_EVENT',
  FAILED_TO_REMOVE_EVENT: 'event/FAILED_TO_REMOVE_EVENT',

  WILL_UPDATE_EVENT: 'event/WILL_UPDATE_EVENT',
  DID_UPDATE_EVENT: 'event/DID_UPDATE_EVENT',
  FAILED_TO_UPDATE_EVENT: 'event/FAILED_TO_UPDATE_EVENT',
};

export default event;

// Get events
export const getEvents = () => dispatch => {
  dispatch({
    type: event.WILL_GET_EVENTS,
  });

  axios
    .get('/api/events')
    .then(res => {
      dispatch({
        type: event.DID_GET_EVENTS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: event.FAILED_TO_GET_EVENTS,
        payload: err,
      });
    });
};

// Add event
export const addEvent = eventData => dispatch => {
  const { name, date, address, programme, members, infoLink, locale } = eventData;

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    name,
    date,
    address,
    programme,
    members,
    infoLink,
    locale,
  });

  dispatch({
    type: event.WILL_ADD_EVENT,
  });

  axios
    .post('/api/events', body, config)
    .then(res => {
      dispatch({
        type: event.DID_ADD_EVENT,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: event.FAILED_TO_ADD_EVENT,
        payload: err,
      });
    });
};

// Update event
export const updateEvent = eventData => dispatch => {
  const { name, date, address, programme, members, infoLink, id, locale } = eventData;

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    name,
    date,
    address,
    programme,
    members,
    infoLink,
    id,
    locale,
  });

  dispatch({
    type: event.WILL_UPDATE_EVENT,
  });

  axios
    .put('/api/events', body, config)
    .then(res => {
      dispatch({
        type: event.DID_UPDATE_EVENT,
        payload: res.data,
      });
    })
    .then(() => dispatch(getEvents()))
    .catch(err => {
      dispatch({
        type: event.FAILED_TO_UPDATE_EVENT,
        payload: err,
      });
    });
};

// Remove event
export const removeEvent = id => dispatch => {
  dispatch({
    type: event.WILL_REMOVE_EVENT,
  });

  axios
    .delete(`/api/events/${id}`)
    .then(res => {
      dispatch({
        type: event.DID_REMOVE_EVENT,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: event.FAILED_TO_REMOVE_EVENT,
        payload: err,
      });
    });
};
