import {
  AppBar,
  Container,
  Grid,
  Hidden,
  IconButton,
  Link,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import React from 'react';
import { RiMenu4Fill as MenuIcon } from 'react-icons/ri';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import Logo2 from './assets/logo2.png';
import NavLinks from './navLinks';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: '100%',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: '64px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  links: {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'none',
      opacity: 0.6,
    },
  },
}));

const NavBar = ({ toggleDrawer, auth: { loading } }) => {
  const classes = useStyles();
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 10 });
  const location = useLocation();
  const isOnDashboard = location.pathname.includes('dashboard');

  return (
    <AppBar
      color="secondary"
      position="fixed"
      elevation={isOnDashboard ? 5 : trigger ? 5 : 0}
      className={classes.root}
    >
      <Container maxWidth="lg" disableGutters>
        <Toolbar className={classes.toolbar}>
          <Typography variant="body1" color="textPrimary">
            <Link
              underline="none"
              className={classes.links}
              style={{
                display: 'flex',
                maxHeight: '64px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              component={RouterLink}
              to="/"
            >
              <img
                src={Logo2}
                alt="Oros logo"
                style={{ width: '100px', height: '120px' }}
              />
              Oros Ensemble
            </Link>
          </Typography>
          <Hidden smDown>
            {!loading && (
              <Grid item md={8}>
                <NavLinks />
              </Grid>
            )}
          </Hidden>

          <Hidden mdUp>
            <IconButton
              onClick={() => toggleDrawer()}
              className={classes.menuButton}
              color="primary"
            >
              <MenuIcon size={30} />
            </IconButton>
          </Hidden>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
