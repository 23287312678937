import { Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React from 'react';
import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles(theme => ({
  titleContainer: {
    marginTop: '1rem',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  line: {
    height: '2px',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  subtitle: {
    fontSize: '22px',
    fontWeight: 'bold',
  },
  title: {
    fontSize: '22px',
    fontWeight: 'bold',
  },
  link: {
    fontWeight: 'bold',
    '&:hover': {
      opacity: 0.6,
    },
  },
}));

export default function Title({ subHeader, header, link }) {
  const classes = useStyles();

  return (
    <div className={classes.titleContainer}>
      <Typography className={classes.subtitle}>{subHeader}</Typography>
      <Divider variant="fullWidth" className={classes.line} />
      {!link && (
        <Typography className={classes.title} color="textPrimary">
          {header}
        </Typography>
      )}
      {link && (
        <Typography className={classes.title} color="textPrimary">
          <Link
            className={classes.link}
            to={link}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {header}
          </Link>
        </Typography>
      )}
    </div>
  );
}
