import axios from 'axios';

const carousel = {
  WILL_GET_SLIDES: 'carousel/WILL_GET_SLIDES',
  FAILED_GET_SLIDES: 'carousel/GET_SLIDES',
  DID_GET_SLIDES: 'carousel/DID_GET_SLIDES',

  WILL_UPLOAD_SLIDES: 'gallery/WILL_UPLOAD_SLIDES',
  DID_UPLOAD_SLIDES: 'gallery/DID_UPLOAD_SLIDES',
  FAILED_TO_UPLOAD_SLIDES: 'gallery/FAILED_TO_UPLOAD_SLIDES',

  WILL_REMOVE_SLIDE: 'gallery/WILL_REMOVE_SLIDE',
  DID_REMOVE_SLIDE: 'gallery/DID_REMOVE_SLIDE',
  FAILED_TO_REMOVE_SLIDE: 'gallery/FAILED_TO_REMOVE_SLIDE',
};

export default carousel;

// Get home page text
export const getSlides = () => dispatch => {
  dispatch({
    type: carousel.WILL_GET_SLIDES,
  });

  axios
    .get('/api/carousel')
    .then(res => {
      dispatch({
        type: carousel.DID_GET_SLIDES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: carousel.FAILED_GET_SLIDES,
        payload: err,
      });
    });
};

export const uploadSlides = images => dispatch => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const data = new FormData();

  for (let index = 0; index < images.files.length; index++) {
    data.append('file', images.files[index]);
  }

  dispatch({ type: carousel.WILL_UPLOAD_SLIDES });

  axios
    .post('/api/carousel', data, config)
    .then(res => {
      dispatch({
        type: carousel.DID_UPLOAD_SLIDES,
        payload: res.data,
      });
    })
    .then(() => {
      dispatch(getSlides());
    })
    .catch(err => {
      dispatch({
        type: carousel.FAILED_TO_UPLOAD_SLIDES,
        payload: err,
      });
    });
};

export const deleteSlide = id => dispatch => {
  dispatch({
    type: carousel.WILL_REMOVE_SLIDE,
  });

  axios
    .delete(`/api/carousel/${id}`)
    .then(res => {
      dispatch({
        type: carousel.DID_REMOVE_SLIDE,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: carousel.FAILED_TO_REMOVE_SLIDE,
        payload: err,
      });
    });
};
