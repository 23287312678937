import { Grid, Link, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MdExpandMore as ExpandMoreIcon } from 'react-icons/md';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Flag from 'react-world-flags';
import { NavHashLink as NavLink } from 'react-router-hash-link';

import AuthLinks from './AuthLinks';
import MediaDropMenu from './MediaDropMenu';
import AboutUsDropMenu from './AboutUsDropMenu';

const useStyles = makeStyles(theme => ({
  links: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: '18px',
    '&:hover': {
      textDecoration: 'none',
      opacity: 0.6,
    },
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.text.secondary,
    }
  },
  localeLink: {
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'none',
      opacity: 0.6,
    },
  },
  openMenuIcon: {
    color: theme.palette.text.primary,
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer',
    },
  },
  menu: { backgroundColor: theme.palette.secondary.main },
}));

const NavLinks = ({
  alignment = 'row',
  onClose,
  auth: { isAuthenticated },
}) => {
  const classes = useStyles();

  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState('en');

  const [mediaAnchorEl, setMediaAnchorEl] = React.useState(null);
  const [aboutAnchorEl, setAboutAnchorEl] = React.useState(null);

  const handleChange = event => {
    setLang(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  const handleMediaClick = event => {
    setMediaAnchorEl(event.currentTarget);
  };

  const handleMediaClose = () => {
    setMediaAnchorEl(null);
  };

  const handleAboutClick = event => {
    setAboutAnchorEl(event.currentTarget);
  };

  const handleAboutClose = () => {
    setAboutAnchorEl(null);
  };

  return (
    <Grid
      container
      spacing={2}
      direction={alignment}
      justify="flex-end"
      alignItems="center"
    >
      <Grid item>
        <Typography
          variant="body1"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <NavLink
            onClick={onClose}
            className={classes.links}
            style={{
              textDecoration: 'none',
            }}
            scroll={el =>
              el.scrollIntoView({ behavior: 'smooth', inline: 'start' })
            }
            to="/us"
          >
            {t('navbar.us')}
          </NavLink>
          <ExpandMoreIcon
            onClick={event => handleAboutClick(event)}
            className={classes.openMenuIcon}
            size={25}
          />
        </Typography>
        <AboutUsDropMenu
          anchor={aboutAnchorEl}
          handleClose={handleAboutClose}
          onClose={onClose}
        />
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <NavLink
            className={classes.links}
            style={{
              textDecoration: 'none',
            }}
            scroll={el =>
              el.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
            to="/media/#media"
            onClick={onClose}
          >
            {t('navbar.media')}
          </NavLink>
          <ExpandMoreIcon
            onClick={event => handleMediaClick(event)}
            className={classes.openMenuIcon}
            size={25}
          />
        </Typography>
        <MediaDropMenu
          anchor={mediaAnchorEl}
          handleClose={handleMediaClose}
          onClose={onClose}
        />
      </Grid>
      <Grid item>
        <Typography variant="body1">
          <Link
            onClick={onClose}
            underline="none"
            className={classes.links}
            component={RouterLink}
            to="/sound"
          >
            {t('navbar.sound')}
          </Link>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          <Link
            onClick={onClose}
            underline="none"
            className={classes.links}
            component={RouterLink}
            to="/concerts"
          >
            {t('navbar.concerts')}
          </Link>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          <Link
            onClick={onClose}
            underline="none"
            className={classes.links}
            component={RouterLink}
            to="/press"
          >
            {t('navbar.press')}
          </Link>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          <Link
            onClick={onClose}
            underline="none"
            className={classes.links}
            component={RouterLink}
            to="/contact"
          >
            {t('navbar.contact')}
          </Link>
        </Typography>
      </Grid>
      <Grid item>
        <Select value={lang} onChange={handleChange}>
          <MenuItem value={'en'}>
            <Flag code={'gb'} height="20" width="20" />
          </MenuItem>
          <MenuItem value={'gr'}>
            <Flag code={'gr'} height="20" width="20" />
          </MenuItem>
        </Select>
      </Grid>
      {isAuthenticated && <AuthLinks onClose={onClose} />}
    </Grid>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(NavLinks);
