import media from '../actions/media';

const initialState = {
  gotMedia: false,
  images: {
    loading: false,
    imagesList: [],
  },
  videos: {
    loading: true,
    videoList: [],
  },
  logos: {
    loading: true,
    logoList: [],
  },
  sounds: {
    loading: true,
    soundList: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case media.WILL_GET_IMAGES:
    case media.WILL_REMOVE_IMAGE:
    case media.WILL_UPLOAD_IMAGES:
      return {
        ...state,
        images: { ...state.images, loading: true },
      };

    case media.WILL_GET_VIDEOS:
    case media.WILL_REMOVE_VIDEO:
    case media.WILL_ADD_VIDEO:
      return {
        ...state,
        videos: { ...state.videos, loading: true },
      };

    case media.WILL_GET_LOGOS:
    case media.WILL_REMOVE_LOGO:
    case media.WILL_UPLOAD_LOGOS:
      return {
        ...state,
        logos: { ...state.logos, loading: true },
      };

    case media.WILL_GET_SOUNDS:
    case media.WILL_REMOVE_SOUND:
    case media.WILL_ADD_SOUND:
      return {
        ...state,
        sounds: { ...state.sounds, loading: true },
      };

    case media.DID_GET_MEDIA:
      return {
        ...state,
        gotMedia: true,
      };

    case media.DID_GET_IMAGES:
      return {
        ...state,
        images: {
          loading: false,
          imagesList: action.payload,
        },
      };

    case media.DID_UPLOAD_IMAGES:
      return {
        ...state,
        images: {
          ...state.images,
          loading: false,
        },
      };

    case media.DID_REMOVE_IMAGE:
      return {
        ...state,
        images: {
          ...state.images,
          loading: false,
          imagesList: state.images.imagesList.filter(
            image => image._id !== action.payload._id,
          ),
        },
      };

    case media.DID_GET_LOGOS:
      return {
        ...state,
        logos: {
          loading: false,
          logoList: action.payload,
        },
      };

    case media.DID_UPLOAD_LOGOS:
      return {
        ...state,
        logos: {
          ...state.logos,
          loading: false,
        },
      };

    case media.DID_REMOVE_LOGO:
      return {
        ...state,
        logos: {
          ...state.logos,
          loading: false,
          logoList: state.logos.logoList.filter(
            logo => logo._id !== action.payload._id,
          ),
        },
      };

    case media.DID_GET_VIDEOS:
      return {
        ...state,
        videos: {
          loading: false,
          videoList: action.payload,
        },
      };

    case media.DID_ADD_VIDEO:
      return {
        ...state,
        videos: {
          ...state.videos,
          loading: false,
          videoList: [...state.videos.videoList, action.payload],
        },
      };

    case media.DID_REMOVE_VIDEO:
      return {
        ...state,
        videos: {
          loading: false,
          videoList: state.videos.videoList.filter(
            video => video._id !== action.payload._id,
          ),
        },
      };

    case media.DID_GET_SOUNDS:
      return {
        ...state,
        sounds: {
          loading: false,
          soundList: action.payload,
        },
      };

    case media.DID_ADD_SOUND:
      return {
        ...state,
        sounds: {
          ...state.sounds,
          loading: false,
          soundList: [...state.sounds.soundList, action.payload],
        },
      };

    case media.DID_REMOVE_SOUND:
      return {
        ...state,
        sounds: {
          loading: false,
          soundList: state.sounds.soundList.filter(
            sound => sound._id !== action.payload._id,
          ),
        },
      };

    case media.FAILED_TO_GET_IMAGES:
    case media.FAILED_TO_UPLOAD_IMAGES:
    case media.FAILED_TO_REMOVE_IMAGE:
      return {
        ...state,
        images: {
          ...state.images,
          loading: false,
          error: action.payload,
        },
      };

    case media.FAILED_TO_GET_LOGOS:
    case media.FAILED_TO_UPLOAD_LOGOS:
    case media.FAILED_TO_REMOVE_LOGO:
      return {
        ...state,
        logos: {
          ...state.logos,
          loading: false,
          error: action.payload,
        },
      };

    case media.FAILED_TO_GET_VIDEOS:
    case media.FAILED_TO_ADD_VIDEO:
    case media.FAILED_TO_REMOVE_VIDEO:
      return {
        ...state,
        videos: {
          ...state.videos,
          loading: false,
          error: action.payload,
        },
      };

    case media.FAILED_TO_GET_SOUNDS:
    case media.FAILED_TO_ADD_SOUND:
    case media.FAILED_TO_REMOVE_SOUND:
      return {
        ...state,
        sounds: {
          ...state.sounds,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};
