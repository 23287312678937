import {
  CardContent,
  Divider,
  Link as MLink,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef } from 'react';
import { convertDate } from 'src/utils/dateConverter';
import gsap from 'gsap';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 30,
    height: '450px',
    overflow: 'auto',
    msOverflowStyle: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '98%',
    },
  },
  upperContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  dateText: {
    fontWeight: '700',
    fontSize: '12px',
  },
  lowerContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'baseline',
    alignItems: 'flex-start',
  },
  titleText: {
    fontWeight: '700',
    color: theme.palette.text.primary,
  },
  link: {
    marginTop: '2rem',
    '&:hover': {
      opacity: 0.6,
    },
  },
  divider: {
    height: '.05rem',
    backgroundColor: theme.palette.text.primary,
    margin: '0 1rem',
  },
}));

export default function ArticleCard({ articleInfo }) {
  const classes = useStyles();
  const card = useRef(null);

  useEffect(() => {
    if (card) {
      gsap.from(card.current, {
        x: 100,
        duration: 1,
        autoAlpha: 0,
        ease: 'none',
        scrollTrigger: {
          trigger: card.current,
          start: 'top bottom-=100',
          toggleActions: 'play none none reverse',
        },
      });
    }
  }, [card]);

  const { link, title, siteName, description, date } = articleInfo;
  return (
    <div ref={card} className={classes.card}>
      <CardContent className={classes.upperContent}>
        <Typography variant="body1" className={classes.titleText}>
          {title}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {siteName}
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          gutterBottom
          className={classes.dateText}
        >
          {convertDate(date, 'dd/MM/yyyy')}
        </Typography>
      </CardContent>
      <Divider
        variant="fullWidth"
        orientation="horizontal"
        className={classes.divider}
      />
      <CardContent className={classes.lowerContent}>
        <Typography variant="body1" color="textPrimary" gutterBottom>
          {description}
        </Typography>
        <MLink
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.link}
          >
            {'Read More'}
          </Typography>
        </MLink>
      </CardContent>
    </div>
  );
}
