import axios from 'axios';

const welcome = {
  WILL_GET_VIDEOS: 'welcome/WILL_GET_VIDEOS',
  FAILED_GET_VIDEOS: 'welcome/GET_VIDEOS',
  DID_GET_VIDEOS: 'welcome/DID_GET_VIDEOS',

  WILL_ADD_VIDEO: 'welcome/WILL_ADD_VIDEO',
  DID_ADD_VIDEO: 'welcome/DID_ADD_VIDEO',
  FAILED_TO_ADD_VIDEO: 'welcome/FAILED_TO_ADD_VIDEO',

  WILL_UPDATE_VIDEO: 'welcome/WILL_UPDATE_VIDEO',
  DID_UPDATE_VIDEO: 'welcome/DID_UPDATE_VIDEO',
  FAILED_TO_UPDATE_VIDEO: 'welcome/FAILED_TO_UPDATE_VIDEO',
};

export default welcome;

// Get home page text
export const getVideos = () => dispatch => {
  dispatch({
    type: welcome.WILL_GET_VIDEOS,
  });

  axios
    .get('/api/videos')
    .then(res => {
      dispatch({
        type: welcome.DID_GET_VIDEOS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: welcome.FAILED_GET_VIDEOS,
        payload: err,
      });
    });
};

export const addVideo = videoLink => dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ videoLink });

  dispatch({ type: welcome.WILL_ADD_VIDEO });

  axios
    .post('/api/videos', body, config)
    .then(res => {
      dispatch({
        type: welcome.DID_ADD_VIDEO,
        payload: res.data,
      });
    })
    .then(() => {
      dispatch(getVideos());
    })
    .catch(err => {
      dispatch({
        type: welcome.FAILED_TO_ADD_VIDEO,
        payload: err,
      });
    });
};

export const updateVideo = (id, newLink) => dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ video: newLink });

  dispatch({
    type: welcome.WILL_UPDATE_VIDEO,
  });

  axios
    .put(`/api/videos/${id}`, body, config)
    .then(res => {
      dispatch({
        type: welcome.DID_UPDATE_VIDEO,
        payload: res.data,
      });
    })
    .then(() => {
      dispatch(getVideos());
    })
    .catch(err => {
      dispatch({
        type: welcome.FAILED_TO_UPDATE_VIDEO,
        payload: err,
      });
    });
};
