import React, { useEffect } from 'react';
import HashLinkObserver from 'react-hash-link';
import { connect } from 'react-redux';
import { getMedia } from 'src/actions/media';

import ImagesSection from './ImagesSection';
import LogoSection from './LogoSection';
import VideoSection from './VideoSection';

const MediaContainer = ({
  images,
  videos,
  logos,
  getMedia,
  gotMedia,
}) => {
  useEffect(() => {
    if (!gotMedia) {
      getMedia();
    }
  }, [gotMedia, getMedia]);

  return (
    <div id="media" style={{ display: 'flex', flexDirection: 'column' }}>
      <HashLinkObserver isPageLoading />
      <div id="videos" style={{ minHeight: '100%' }}>
        <VideoSection videos={videos} />
      </div>
      <div id="images" style={{ minHeight: '100%' }}>
        <ImagesSection images={images} />
      </div>
      <div id="logos" style={{ minHeight: '100%' }}>
        <LogoSection logos={logos} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  images: state.media.images,
  videos: state.media.videos,
  logos: state.media.logos,
  sounds: state.media.sounds,
  gotMedia: state.media.gotMedia,
});

const mapDispatchToProps = {
  getMedia,
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaContainer);
