import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { compareAsc, compareDesc, parseISO } from 'date-fns';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getEvents } from 'src/actions/events';
import Title from 'src/shared/Title';

import EmptyEventCard from '../Concerts/EmptyEventCard';
import EventCard from './EventCard';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginTop: '10rem',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
      justifyContent: 'center',
      width: '100%',
    },
  },
  emptyCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionEventsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ComingEvents = ({ eventList, loading, getEvents }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  const cleanedList = eventList
    ?.filter(event => compareDesc(parseISO(event.date), Date.now()) === -1)
    .filter(event => event.locale === i18n.language)
    .sort((a, b) => compareAsc(parseISO(a.date), parseISO(b.date)));

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.titleContainer}>
        <Title
          subHeader={t('homePage.comingEvents.title')}
          header={t('homePage.comingEvents.subtitle')}
          link={'/concerts'}
        />
      </div>

      {!cleanedList?.length && (
        <div className={classes.emptyCardContainer}>
          <EmptyEventCard />
        </div>
      )}
      <div className={classes.sectionEventsContainer}>
        {cleanedList?.map(
          ({ _id, name, date, address, members, infoLink }, index) => (
            <EventCard
              key={_id}
              eventName={name}
              eventDate={date}
              eventAddress={address}
              eventMembers={members}
              eventInfoLink={infoLink}
            />
          ),
        )}
      </div>
    </Container>
  );
};

const mapStateToProps = state => ({
  eventList: state.events.eventsList,
  loading: state.events.loading,
});

const mapDispatchToProps = { getEvents };

export default connect(mapStateToProps, mapDispatchToProps)(ComingEvents);
