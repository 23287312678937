import { Divider, Typography } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  titleContainer: {
    marginTop: '5rem',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  },
  line: {
    height: '2px',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  subtitle: {
    fontSize: '22px',
    fontWeight: 'bold',
  },
  title: {
    fontSize: '22px',
    fontWeight: 'bold',
  },
  link: {
    fontWeight: 'bold',
    '&:hover': {
      opacity: 0.6,
    },
  },
}));

export default function Title({ subHeader, header, link }) {
  const classes = useStyles();
  const titleContainer = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      titleContainer.current,
      {
        opacity: 0,
        x: -200,
      },
      {
        duration: 1,
        opacity: 1,
        x: 0,
        ease: 'power3.inOut',
        scrollTrigger: {
          trigger: titleContainer.current,
          start: 'top center+=100',
          toggleActions: 'play none none reverse',
        },
      },
    );
  }, []);

  return (
    <div ref={titleContainer} className={classes.titleContainer}>
      <Typography className={classes.subtitle} color="textPrimary">{subHeader}</Typography>
      <Divider variant="fullWidth" className={classes.line} />
      {!link && (
        <Typography className={classes.title} color="primary">
          {header}
        </Typography>
      )}
      {link && (
        <Typography className={classes.title} color="primary">
          <Link
            className={classes.link}
            to={link}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {header}
          </Link>
        </Typography>
      )}
    </div>
  );
}
