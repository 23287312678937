import axios from 'axios';

const contact = {
  WILL_SEND_MESSAGE: 'contact/WILL_SEND_MESSAGE',
  DID_SEND_MESSAGE: 'contact/DID_SEND_MESSAGE',
  FAILED_TO_SEND_MESSAGE: 'contact/FAILED_TO_SEND_MESSAGE',
};

export default contact;

export const sendMessage = (name, email, message) => (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ name, email, message });

  dispatch({
    type: contact.WILL_SEND_MESSAGE,
  });

  axios
    .post('/api/contact', body, config)
    .then((res) => {
      dispatch({
        type: contact.DID_SEND_MESSAGE,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log('sendMessage -> err', err);
      const errors = err.response.data.errors;

      dispatch({
        type: contact.FAILED_TO_SEND_MESSAGE,
        payload: errors,
      });
    });
};
