import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { compareAsc, parseISO } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EventCard from 'src/components/HomePage/EventCard';
import Title from 'src/shared/Title';

import EmptyEventCard from './EmptyEventCard';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flex: 1,
    marginTop: '5rem',
    marginBottom: '8rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  sectionEventsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
}));

export default function ArchiveEvents({ eventList }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <div className={classes.titleContainer}>
        <Title
          subHeader={t('concerts.archive.titleA')}
          header={t('concerts.archive.titleB')}
        />
      </div>

      {eventList.length === 0 && <EmptyEventCard />}

      <div className={classes.sectionEventsContainer}>
        {!!eventList.length &&
          eventList
            .sort((a, b) => compareAsc(parseISO(b.date), parseISO(a.date)))
            .map(({ _id, name, date, address, members, infoLink }, index) => (
              <EventCard
                key={_id}
                eventName={name}
                eventDate={date}
                eventAddress={address}
                eventMembers={members}
                eventInfoLink={infoLink}
              />
            ))}
      </div>
    </Container>
  );
}
