import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      left: '5%',
    },
    width: '40%',
    left: '35%',
    top: '20%',
    maxHeight: '75%',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'scroll',
  },
}));

const AddModal = ({ children, isOpen, close }) => {
  const classes = useStyles();
  return (
    <Modal open={isOpen} onClose={close}>
      <div className={classes.paper}>{children}</div>
    </Modal>
  );
};

export default AddModal;
