import auth from '../actions/auth';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  user: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case auth.USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
      };

    case auth.LOAD_USER_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };

    case auth.WILL_LOGIN:
      return {
        ...state,
        loading: true,
      };

    case auth.DID_LOGIN:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        error: null,
        ...action.payload,
      };

    case auth.FAILED_LOGIN:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };

    case auth.WILL_REGISTER:
      return {
        ...state,
        loading: true,
      };

    case auth.DID_REGISTER:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        error: null,
        ...action.payload,
      };

    case auth.FAILED_REGISTER:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };

    case auth.LOGOUT_USER:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
      };

    default:
      return state;
  }
};
