import { Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavHashLink as NavLink } from 'react-router-hash-link';

const useStyles = makeStyles(theme => ({
  links: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
      opacity: 0.6,
    },
  },
  menu: { backgroundColor: theme.palette.secondary.main },
}));

export default function AboutUsDropMenu({ anchor, handleClose, onClose }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Menu
      classes={{
        paper: classes.menu,
      }}
      onMouseLeave={Boolean(anchor) ? handleClose : null}
      disableAutoFocusItem
      id="simple-menu"
      anchorEl={anchor}
      keepMounted
      style={{ marginTop: '2rem', left: -50 }}
      open={Boolean(anchor)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleClose}>
        <NavLink
          className={classes.links}
          style={{
            textDecoration: 'none',
          }}
          scroll={el =>
            el.scrollIntoView({
              behavior: 'smooth',
            })
          }
          to="/us/#about"
          onClick={onClose}
        >
          {t('navbar.dropDown.aboutUs')}
        </NavLink>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <NavLink
          className={classes.links}
          style={{
            textDecoration: 'none',
          }}
          scroll={
            el =>
              el.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
              })
            // el.scrollTop({ behavior: 'smooth', block: 'start' })
          }
          to="/us/#members"
          onClick={onClose}
        >
          {t('navbar.dropDown.members')}
        </NavLink>
      </MenuItem>
    </Menu>
  );
}
