import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getVideos, addVideo, updateVideo } from 'src/actions/welcome';

import LoadingSpinner from 'src/shared/LoadingSpinner';
import VideoInfo from './VideoInfo';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '3rem',
  },
  videoContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const WelcomeVideos = ({ videoInfo, getVideos }) => {
  const classes = useStyles();

  useEffect(() => {
    getVideos();
  }, [getVideos]);

  const { loading, videos } = videoInfo;

  if (loading)
    return (
      <Container component="main" className={classes.root}>
        <LoadingSpinner />;
      </Container>
    );

  return (
    <Container component="main" className={classes.root}>
      <Typography variant="h5" align="center" color="secondary">
        Work Videos
      </Typography>
      <div className={classes.videoContainer}>
        {videos.map(video => (
          <VideoInfo videoData={video} key={video._id} />
        ))}
      </div>
    </Container>
  );
};

const mapStateToProps = state => ({
  videoInfo: state.welcome,
});

const mapDispatchToProps = { getVideos, addVideo, updateVideo };

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeVideos);
