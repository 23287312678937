import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import gsap from 'gsap';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Title from 'src/shared/Title';

import MemberCard from './MemberCard';
import MEMBER_LIST from './memberList';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100%',
    paddingTop: '2rem',
    marginBottom: '10rem',
    overflow: 'hidden',
  },
  memberImagesContainer: {
    display: 'flex',
    margin: '5rem auto',
    alignItems: 'center',
    paddingBottom: '3rem',
    maxWidth: '80%',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      padding: '1rem',
      maxWidth: '100%',
    },
  },
  memberImage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    height: '460px',
    paddingBottom: '2rem',
    '&:hover': {
      opacity: 0.9,
      cursor: 'pointer',
      transform: 'scale(0.99)',
    },
  },
  nameTitle: {
    color: '#fff',
    fontWeight: 'bold',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      marginBottom: '.5rem',
    },
  },
  roleTitle: {
    color: '#fff',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
}));

const Members = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [activeMember, setActiveMember] = useState();
  const [open, setOpen] = useState(false);
  const revealRefs = useRef([]);
  revealRefs.current = [];

  useEffect(() => {
    gsap.fromTo(
      revealRefs.current,
      {
        autoAlpha: 0,
        x: 100,
      },
      {
        x: 0,
        duration: 1,
        autoAlpha: 1,
        ease: 'none',
        stagger: 0.3,
        scrollTrigger: {
          trigger: revealRefs.current,
          start: 'top center+=100',
          toggleActions: 'play none none none',
        },
      },
    );
  }, []);

  const makeMemberActive = member => {
    setActiveMember(member);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addToRefs = el => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  return (
    <Container id="members" maxWidth="lg" className={classes.container}>
      <Title
        subHeader={t('aboutUs.aboutSection.titleA')}
        header={t('aboutUs.aboutSection.titleC')}
      />

      <Container maxWidth="md" style={{ marginTop: '5rem' }}>
        <Grid container spacing={2}>
          {MEMBER_LIST.map(member => (
            <Grid ref={addToRefs} key={member.id} xs={12} md={6} item>
              <div
                className={classes.memberImage}
                onClick={() => makeMemberActive(member)}
                style={{
                  backgroundImage: `url(${member.photo})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    color="primary"
                    variant="h5"
                    className={classes.nameTitle}
                  >
                    {t(`aboutUs.members.${member.name}.name`)}
                  </Typography>
                  <Typography
                    color="primary"
                    align="center"
                    variant="body1"
                    className={classes.roleTitle}
                  >
                    {t(`aboutUs.members.${member.name}.role`)}
                  </Typography>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
      {activeMember && (
        <MemberCard member={activeMember} onClose={handleClose} isOpen={open} />
      )}
    </Container>
  );
};

export default Members;
