import { CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    maxWidth: '50%',
    minWidth: '450px',
    marginTop: '2rem',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
  eventInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-around',
    },
  },
  titleText: {
    fontWeight: '700',
  },
}));

gsap.registerPlugin(ScrollTrigger);

export default function EmptyEventCard() {
  const classes = useStyles();
  const { t } = useTranslation();

  const card = useRef(null);

  useEffect(() => {
    if (card) {
      gsap.fromTo(
        card.current,
        {
          autoAlpha: 0,
          x: -200,
        },
        {
          x: 0,
          duration: 1,
          autoAlpha: 1,
          ease: 'none',
          scrollTrigger: {
            trigger: card.current,
            start: 'top bottom-=100',
            toggleActions: 'play none none reverse',
          },
        },
      );
    }
  }, [card]);

  return (
    <div ref={card} className={classes.card}>
      <CardContent className={classes.eventInfo}>
        <Typography
          color="primary"
          align="center"
          variant="body1"
          className={classes.titleText}
        >
          {t('concerts.upcoming.emptyList')}
        </Typography>
      </CardContent>
    </div>
  );
}
