import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImgsViewer from 'react-images-viewer';
import LoadingSpinner from 'src/shared/LoadingSpinner';
import Title from 'src/shared/Title';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginTop: '8rem',
    flexDirection: 'column',
    marginBottom: '8rem',
  },
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '3rem',
  },
  imageContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '3rem',
  },
  image: {
    width: '100%',
    maxHeight: '200px',
    height: '300px',
    maxWidth: '300px',
    '&:hover': {
      opacity: 0.9,
      animation: `$elevate 1000ms ${theme.transitions.easing.easeInOut}`,
      cursor: 'pointer',
    },
  },
  '@keyframes elevate': {
    '0%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(1.1)',
    },
  },
}));

const LogosSection = ({ logos, getLogos }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const [currentLogo, setCurrentLogo] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const { logoList, loading } = logos;
  const logosR = logoList.map(image => ({ src: image.image }));

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Title
        subHeader={t('media.logosSection.titleA')}
        header={t('media.logosSection.titleB')}
      />
      {loading && (
        <Container component="main" className={classes.root}>
          <LoadingSpinner />
        </Container>
      )}
      {!loading && (
        <div className={classes.imageContainer}>
          <Grid container spacing={1} alignItems="center" justify="center">
            {logoList.map(({ image, _id }, index) => (
              <Grid key={_id} md={6} lg={4} container justify="center" item>
                <div
                  className={classes.image}
                  onClick={() => {
                    setCurrentLogo(index);
                    setIsLightboxOpen(true);
                  }}
                  style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    paddingBottom: '2rem',
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      <ImgsViewer
        backdropCloseable={true}
        showCloseBtn
        imgs={logosR}
        isOpen={isLightboxOpen}
        currImg={currentLogo}
        onClickPrev={() => setCurrentLogo((currentLogo - 1) % logoList.length)}
        onClickNext={() => setCurrentLogo((currentLogo + 1) % logoList.length)}
        onClose={() => setIsLightboxOpen(false)}
      />
    </Container>
  );
};

export default LogosSection;
