import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { compareDesc, parseISO } from 'date-fns';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getEvents } from 'src/actions/events';
import LoadingSpinner from 'src/shared/LoadingSpinner';

import ArchiveEvents from './ArchiveEvents';
import ComingEvents from './ComingEvents';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  container: {
    overflowX: 'hidden',
  },
}));

const Concerts = ({ eventList, loading, getEvents }) => {
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  if (loading)
    return (
      <Container component="main" className={classes.root}>
        <LoadingSpinner />
      </Container>
    );

  const filterEventsByLocale = eventList?.filter(
    event => event.locale === i18n.language,
  );

  const comingEvents = filterEventsByLocale?.filter(
    event => compareDesc(parseISO(event.date), Date.now()) === -1,
  );

  const archivedEvents = filterEventsByLocale?.filter(
    event => compareDesc(parseISO(event.date), Date.now()) === 1,
  );

  return (
    <Container maxWidth="lg" className={classes.container}>
      <ComingEvents eventList={comingEvents} />
      <ArchiveEvents eventList={archivedEvents} />
    </Container>
  );
};

const mapStateToProps = state => ({
  eventList: state.events.eventsList,
  loading: state.events.loading,
});

const mapDispatchToProps = { getEvents };

export default connect(mapStateToProps, mapDispatchToProps)(Concerts);
