import {
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Typography,
} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { RiAddFill as AddIcon, RiCloseLine as CloseIcon } from 'react-icons/ri';
import { connect } from 'react-redux';
import { getArticles } from 'src/actions/press';
import AddModal from 'src/shared/AddModal';
import LoadingSpinner from 'src/shared/LoadingSpinner';

import AddArticle from './AddArticle';
import ArticlesInfo from './ArticlesInfo';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '3rem',
  },
  paper: {
    textAlign: 'center',
    height: '100%',
    paddingTop: '100px',
  },
  header: {
    marginBottom: '50px',
  },
  articleCard: {
    padding: '15px',
    marginTop: '2rem',
  },
  fabIcon: {
    color: '#fff',
    backgroundColor: theme.palette.primary,
    '&:hover': {
      opacity: '0.9',
    },
    margin: '5rem',
  },
}));

const Press = ({ pressInfo, getArticles, error }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);

  useEffect(() => {
    getArticles();
  }, [getArticles]);

  useEffect(() => {
    if (error) {
      setOpenError(true);
    }
  }, [error]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const { loading, articleList } = pressInfo;

  if (loading)
    return (
      <Container component="main" className={classes.root}>
        <LoadingSpinner />
      </Container>
    );

  const orderedArticlesByDate = articleList.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });
  return (
    <Container component="main" className={classes.paper}>
      <Typography
        color="secondary"
        align="center"
        component="h1"
        gutterBottom
        variant="h4"
      >
        Your articles
      </Typography>
      <Divider variant="middle" />
      {orderedArticlesByDate.length === 0 && (
        <Typography variant="h5" align="center" color="primary">
          You dont have any articles yet...
        </Typography>
      )}

      <Grid container spacing={2}>
        {orderedArticlesByDate.map(article => (
          <Grid key={article._id} item xs={12} md={6}>
            <Paper className={classes.articleCard}>
              <ArticlesInfo articleInfo={article} />
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Fab
        onClick={handleOpen}
        className={classes.fabIcon}
        color="primary"
        aria-label="add"
      >
        <AddIcon size={25} />
      </Fab>
      <AddModal isOpen={open} close={handleClose}>
        <Typography
          color="primary"
          className={classes.header}
          component="h1"
          variant="h4"
          align="center"
        >
          New Article
        </Typography>
        <AddArticle />
      </AddModal>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        message={error?.msg}
        open={openError}
        autoHideDuration={5000}
        onClose={handleCloseError}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseError}
            >
              <CloseIcon size={25} />
            </IconButton>
          </React.Fragment>
        }
      />
    </Container>
  );
};

const mapStateToProps = state => ({
  pressInfo: state.press,
  error: state.press.error,
});

const mapDispatchToProps = { getArticles };

export default connect(mapStateToProps, mapDispatchToProps)(Press);
