import React from 'react';
import HashLinkObserver from 'react-hash-link';
import AboutUs from './AboutUs';
import Members from './Members';

const HomePageContainer = () => {
  return (
    <>
      <HashLinkObserver />
      <AboutUs />
      <Members />
    </>
  );
};

export default HomePageContainer;
