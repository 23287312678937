import carousel from 'src/actions/carousel';

const initialState = {
  loading: true,
  images: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case carousel.WILL_GET_SLIDES:
      return {
        ...state,
        loading: true,
      };

    case carousel.DID_GET_SLIDES:
      return {
        ...state,
        loading: false,
        images: action.payload,
      };

    case carousel.FAILED_GET_SLIDES:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case carousel.WILL_UPLOAD_SLIDES:
      return {
        ...state,
        loading: true,
      };

    case carousel.DID_UPLOAD_SLIDES:
      return {
        ...state,
        loading: false,
      };

    case carousel.FAILED_TO_UPLOAD_SLIDES:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case carousel.WILL_REMOVE_SLIDE:
      return {
        ...state,
        loading: true,
      };

    case carousel.DID_REMOVE_SLIDE:
      return {
        ...state,
        loading: false,
        images: state.images.filter(image => image._id !== action.payload._id),
      };

    case carousel.FAILED_TO_REMOVE_SLIDE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
