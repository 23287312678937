import { Container, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { getSounds } from 'src/actions/media';
import Title from 'src/shared/AnimatedTitle';
import LoadingSpinner from 'src/shared/LoadingSpinner';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flex: 1,
    minHeight: '85%',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'hidden',
    msOverflowX: 'hidden',
    marginTop: '5rem',
  },
}));

const Sound = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, soundList } = useSelector(state => state.media.sounds);

  useEffect(() => {
    dispatch(getSounds());
  }, [dispatch]);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Title subHeader={t('sound.title')} />

      {loading ? (
        <LoadingSpinner />
      ) : (
        <Container maxWidth="lg" style={{ marginTop: '5rem' }}>
          <Grid container spacing={2} justify="center">
            {soundList.map(sound => (
              <Grid
                item
                key={sound._id}
                xs={12}
                sm={6}
                md={4}
                container
                justify="center"
                alignItems="center"
              >
                <ReactPlayer
                  width="100%"
                  height="100%"
                  loop
                  config={{
                    attributes: {
                      style: {
                        objectFit: 'cover',
                      },
                    },
                  }}
                  controls
                  url={sound.link}
                  playing={false}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </Container>
  );
};

export default Sound;
