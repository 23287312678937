import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Title from 'src/shared/AnimatedTitle';

const aboutImage = require('./assets/aboutUs.jpeg');

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'hidden',
    msOverflowX: 'hidden',
    marginTop: '5rem',
  },
  aboutUsSection: {
    display: 'flex',
    margin: '5rem 2rem',
    alignItems: 'center',
    flexDirection: 'column-reverse',
  },
  imageContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  aboutImage: {
    objectFit: 'cover',
    objectPosition: 'center',
    margin: '3rem 1rem',
    width: '619px',
    height: '431px',
    float: 'left',
    [theme.breakpoints.up('xl')]: {
      width: '50%',
      height: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '60%',
      height: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
    },
  },
  aboutDescriptionContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
    padding: '2rem',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
}));

const AboutUs = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const aboutUs = useRef(null);
  const aboutUsImage = useRef(null);

  useEffect(() => {
    if (aboutUs && aboutUsImage) {
      gsap.from(aboutUs.current, {
        duration: 1.5,
        opacity: 0,
        y: 100,
        delay: 0.5,
        ease: 'power3.inOut',
        scrollTrigger: {
          trigger: aboutUs.current,
        },
      });
      gsap.from(aboutUsImage.current, {
        x: 500,
        duration: 2,
        delay: 1,
        opacity: 0,
        ease: 'power3.out',
        scrollTrigger: {
          start: 'top bottom',
          trigger: aboutUsImage.current,
        },
      });
    }
  }, [aboutUs, aboutUsImage]);

  return (
    <Container maxWidth="lg" className={classes.container} id="about">
      <Title
        header={t('aboutUs.aboutSection.titleB')}
        subHeader={t('aboutUs.aboutSection.titleA')}
      />

      <div className={classes.aboutUsSection}>
        <div ref={aboutUs} className={classes.aboutDescriptionContainer}>
          <Typography
            color="textPrimary"
            variant="body1"
            align="center"
            style={{ whiteSpace: 'break-spaces' }}
          >
            {t('aboutUs.aboutSection.aboutUsText')}
          </Typography>
        </div>

        <div ref={aboutUsImage} className={classes.imageContainer}>
          <img
            alt="Members of the ensemble"
            src={aboutImage}
            className={classes.aboutImage}
          />
        </div>
      </div>
    </Container>
  );
};

export default AboutUs;
