import press from '../actions/press';

const initialState = {
  loading: true,
  articleList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case press.WILL_GET_ARTICLES:
    case press.WILL_UPLOAD_ARTICLE:
    case press.WILL_REMOVE_ARTICLE:
      return {
        ...state,
        loading: true,
      };

    case press.DID_GET_ARTICLES:
      return {
        ...state,
        loading: false,
        articleList: action.payload,
      };

    case press.DID_UPLOAD_ARTICLE:
      return {
        ...state,
        loading: false,
        articleList: [...state.articleList, action.payload],
      };

    case press.DID_REMOVE_ARTICLE:
      return {
        ...state,
        loading: false,
        articleList: state.articleList.filter(
          article => article._id !== action.payload._id,
        ),
      };

    case press.FAILED_TO_GET_ARTICLES:
    case press.FAILED_TO_REMOVE_ARTICLE:
    case press.FAILED_TO_UPLOAD_ARTICLE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
