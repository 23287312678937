import event from '../actions/events';

const initialState = {
  loading: true,
  eventsList: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case event.WILL_GET_EVENTS:
    case event.WILL_ADD_EVENT:
    case event.WILL_REMOVE_EVENT:
    case event.WILL_UPDATE_EVENT:
      return {
        ...state,
        loading: true,
      };

    case event.FAILED_TO_GET_EVENTS:
    case event.FAILED_TO_ADD_EVENT:
    case event.FAILED_TO_REMOVE_EVENT:
    case event.FAILED_TO_UPDATE_EVENT:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case event.DID_GET_EVENTS:
      return {
        ...state,
        loading: false,
        eventsList: action.payload,
      };

    case event.DID_ADD_EVENT:
      return {
        ...state,
        loading: false,
        eventsList: [...state.eventsList, action.payload],
        newEvent: action.payload,
      };

    case event.DID_REMOVE_EVENT:
      return {
        ...state,
        loading: false,
        eventsList: state.eventsList.filter(
          event => event._id !== action.payload._id,
        ),
        removedEvent: action.payload,
      };

    case event.DID_UPDATE_EVENT:
      return {
        ...state,
        loading: false,
        updatedEvent: action.payload,
      };

    default:
      return state;
  }
};
