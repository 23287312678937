import axios from 'axios';

const auth = {
  USER_LOADED: 'auth/USER_LOADED',
  LOAD_USER_FAILED: 'auth/LOAD_USER_FAILED',
  WILL_LOGIN: 'login/WILL_LOGIN',
  DID_LOGIN: 'login/DID_LOGIN',
  FAILED_LOGIN: 'login/FAILED_LOGIN',
  WILL_REGISTER: 'register/WILL_REGISTER',
  DID_REGISTER: 'register/DID_REGISTER',
  FAILED_REGISTER: 'register/FAILED_REGISTER',
  LOGOUT_USER: 'auth/LOGOUT_USER',
};

export default auth;

// Load User
export const loadUser = () => (dispatch) => {
  axios
    .get('/api/auth')
    .then((res) => {
      dispatch({
        type: auth.USER_LOADED,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: auth.LOAD_USER_FAILED,
      });
    });
};

// Login user
export const loginUser = (email, password) => (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ email, password });

  dispatch({
    type: auth.WILL_LOGIN,
  });

  axios
    .post('/api/auth', body, config)
    .then((res) => {
      dispatch({
        type: auth.DID_LOGIN,
        payload: res.data,
      });
    })
    .then(() => dispatch(loadUser()))
    .catch((err) => {
      const errors = err.response.data.errors;

      dispatch({
        type: auth.FAILED_LOGIN,
        payload: errors,
      });
    });
};

// Register user
export const registerUser = (email, password) => (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ email, password });

  dispatch({
    type: auth.WILL_REGISTER,
  });

  axios
    .post('/api/users', body, config)
    .then((res) => {
      dispatch({
        type: auth.DID_REGISTER,
        payload: res.data,
      });
    })
    .then(() => dispatch(loadUser()))
    .catch((err) => {
      const errors = err.response.data.errors;

      dispatch({
        type: auth.FAILED_REGISTER,
        payload: errors,
      });
    });
};

// Logout User
export const logout = () => (dispatch) => {
  dispatch({ type: auth.LOGOUT_USER });
};
