import {
  Button,
  Container,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { removeArticle } from 'src/actions/press';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Flag from 'react-world-flags';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '4rem',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(1),
  },
  playerContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    alignItems: 'center',
  },
  image: {
    maxHeight: 350,
    maxWidth: 400, // 16:9,
  },
  input: {
    color: '#000',
    backgroundColor: '#fff',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  delete: {
    margin: theme.spacing(3, 0, 2),
    color: '#fff',
    backgroundColor: 'red',
  },
}));

const ArticleInfo = ({ articleInfo, removeArticle }) => {
  const classes = useStyles();

  const {
    link,
    _id,
    title,
    siteName,
    description,
    date,
    locale = 'gr',
  } = articleInfo;

  const onSubmit = e => {
    e.preventDefault();

    removeArticle(_id);
  };

  return (
    <Container component="main" className={classes.paper}>
      <form className={classes.form} onSubmit={onSubmit}>
        <TextField
          required
          disabled
          fullWidth
          InputProps={{ className: classes.input }}
          variant="outlined"
          margin="normal"
          label="Title"
          name="title"
          value={title}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            disabled
            required
            format="dd/MM/yyyy"
            className={classes.picker}
            variant="outlined"
            margin="normal"
            label="Date"
            name="date"
            value={date}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>

        <TextField
          required
          disabled
          InputProps={{ className: classes.input }}
          variant="outlined"
          margin="normal"
          fullWidth
          label="Site name"
          name="siteName"
          value={siteName}
        />
        <TextField
          required
          disabled
          InputProps={{ className: classes.input }}
          variant="outlined"
          margin="normal"
          fullWidth
          label="Description"
          name="description"
          value={description}
          multiline
          rowsMax={5}
          rows={5}
          maxLength={10}
        />
        <TextField
          required
          disabled
          InputProps={{ className: classes.input }}
          variant="outlined"
          margin="normal"
          fullWidth
          label="Link"
          name="link"
          value={link}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <h4 style={{ color: '#000' }}>Language</h4>
          <div>
            <Flag
              code={locale === 'en' ? 'gb' : locale}
              height="20"
              width="20"
            />
          </div>
        </div>

        <Button
          className={classes.delete}
          variant="contained"
          color="primary"
          type="submit"
        >
          REMOVE
        </Button>
      </form>
    </Container>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  removeArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleInfo);
