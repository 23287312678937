import { Container, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import Images from './ImagesSection/Images';
import Logos from './LogosSection/Logos';
import Sounds from './SoundsSection/Sounds';
import Videos from './VideoSection/Videos';

const useStyles = makeStyles(theme => ({
  paper: {
    height: '100%',
    paddingTop: '100px',
  },
}));

export default function Media() {
  const classes = useStyles();

  return (
    <Container component="main" className={classes.paper}>
      <Typography
        color="secondary"
        align="center"
        component="h1"
        gutterBottom
        variant="h4"
      >
        Your Media
      </Typography>
      <Divider variant="middle" />
      <Videos />
      <Divider variant="middle" />
      <Images />
      <Divider variant="middle" />
      <Sounds />
      <Divider variant="middle" />
      <Logos />
    </Container>
  );
}
