import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import LoadingSpinner from 'src/shared/LoadingSpinner';
import Title from 'src/shared/Title';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginTop: '8rem',
    flexDirection: 'column',
  },
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '3rem',
  },
  videoContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '3rem',
    padding: '5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
  video: {
    width: '100%',
    minHeight: '350px',
    [theme.breakpoints.up('lg')]: {
      maxHeight: '350px',
      maxWidth: '500px',
    },
  },
}));

const VideoSection = ({ videos }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { videoList, loading } = videos;

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Title
        subHeader={t('media.videosSection.titleA')}
        header={t('media.videosSection.titleB')}
      />
      {loading && (
        <Container component="main" className={classes.root}>
          <LoadingSpinner />
        </Container>
      )}
      {!loading && (
        <div className={classes.videoContainer}>
          <Grid container spacing={3} justify="center">
            {videoList.map(({ link, _id }) => (
              <Grid
                key={_id}
                sm={12}
                md={6}
                lg={8}
                container
                justify="center"
                alignItems="center"
                item
                className={classes.video}
              >
                <ReactPlayer
                  width="100%"
                  height="100%"
                  loop
                  config={{
                    attributes: {
                      style: {
                        objectFit: 'cover',
                      },
                    },
                  }}
                  controls
                  url={link}
                  playing={false}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </Container>
  );
};

export default VideoSection;
