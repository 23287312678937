import { Link, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { logout } from '../../actions/auth';
import { RiLogoutBoxRLine as ExitToAppIcon } from 'react-icons/ri';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  links: {
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'none',
      opacity: 0.6,
    },
  },
}));

const AuthLinks = ({ onClose, logout }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item>
        <Typography variant="body1" color="textPrimary">
          <Link
            onClick={onClose}
            underline="none"
            className={classes.links}
            component={RouterLink}
            to="/dashboard/homePage"
          >
            Dashboard
          </Link>
        </Typography>
      </Grid>
      <Grid item>
        <Link
          onClick={() => {
            logout();
          }}
          underline="none"
          className={classes.links}
          component={RouterLink}
          to="/"
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              className={classes.links}
              variant="body1"
              color="textPrimary"
            >
              Logout
            </Typography>
            <ExitToAppIcon color="primary" size={20} />
          </div>
        </Link>
      </Grid>
    </>
  );
};

const mapDispatchToProps = { logout };

export default connect(() => ({}), mapDispatchToProps)(AuthLinks);
