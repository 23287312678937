import { Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { addVideo } from 'src/actions/media';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345,
  },
  image: {
    height: 250,
    maxWidth: 400, // 16:9,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  input: {
    color: '#000',
    backgroundColor: '#fff',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  delete: {
    margin: theme.spacing(3, 0, 2),

    color: '#fff',
    backgroundColor: 'red',
  },
}));

const AddVideo = ({ addVideo }) => {
  const classes = useStyles();

  const [videoLink, setVideoLink] = useState('');

  const onChange = e => {
    setVideoLink(e.target.value);
  };

  const onSubmit = e => {
    e.preventDefault();

    if (!videoLink.trim()) {
      return;
    }

    addVideo(videoLink);

    setVideoLink('');
  };

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <TextField
        required
        fullWidth
        InputLabelProps={{className: classes.input}}
        InputProps={{ className: classes.input }}
        variant="outlined"
        margin="normal"
        label="Video Link"
        value={videoLink}
        onChange={onChange}
      />

      <Button
        fullWidth
        className={classes.submit}
        variant="contained"
        color="primary"
        type="submit"
      >
        Add Video
      </Button>
    </form>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  addVideo,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddVideo);
