import { Container, Grid, Typography } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { RiAddFill as AddIcon } from 'react-icons/ri';
import { connect } from 'react-redux';
import { getSounds } from 'src/actions/media';
import AddModal from 'src/shared/AddModal';
import LoadingSpinner from 'src/shared/LoadingSpinner';

import AddSound from './AddSound';
import SoundInfo from './SoundInfo';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '5rem',
  },
  videoContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  header: {
    marginBottom: '50px',
  },
  fabIcon: {
    color: '#fff',
    backgroundColor: theme.palette.primary,
    '&:hover': {
      opacity: '0.9',
    },
    margin: '5rem',
  },
}));

const Sounds = ({ sounds, getSounds }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getSounds();
  }, [getSounds]);

  const { loading, soundList } = sounds;

  if (loading)
    return (
      <Container component="main" className={classes.root}>
        <LoadingSpinner />
      </Container>
    );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container component="main" className={classes.root}>
      <Typography variant="h5" align="center" color="secondary">
        Your SoundCloud links
      </Typography>

      {soundList.length === 0 && (
        <Typography variant="h5" align="center" color="primary">
          You dont have any SoundCloud links yet...
        </Typography>
      )}

      <Grid container spacing={2}>
        {soundList.map(sound => (
          <Grid key={sound._id} xs={12} sm={6} md={4} item>
            <SoundInfo soundData={sound} />
          </Grid>
        ))}
      </Grid>

      <Fab
        onClick={handleOpen}
        className={classes.fabIcon}
        color="primary"
        aria-label="add"
      >
        <AddIcon size={30} />
      </Fab>
      <AddModal isOpen={open} close={handleClose}>
        <Typography
          color="primary"
          className={classes.header}
          component="h1"
          variant="h4"
          align="center"
        >
          New SoundCloud link
        </Typography>
        <AddSound />
      </AddModal>
    </Container>
  );
};

const mapStateToProps = state => ({
  sounds: state.media.sounds,
});

const mapDispatchToProps = { getSounds };

export default connect(mapStateToProps, mapDispatchToProps)(Sounds);
