import { Button, TextField, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';

import { updateVideo } from 'src/actions/welcome';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '4rem',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(1),
  },
  playerContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    alignItems: 'center',
  },
  image: {
    maxHeight: 350,
    maxWidth: 400, // 16:9,
  },
  input: {
    color: '#000',
    backgroundColor: '#fff',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const VideoInfo = ({ videoData, updateVideo }) => {
  const classes = useStyles();
  const { link, _id } = videoData;

  const [videoInfo, setVideoInfo] = useState();
  const [newVideolink, setNewVideolink] = useState('');

  useEffect(() => {
    setVideoInfo({
      link: link,
      id: _id,
    });
  }, [link, _id]);

  const onChange = e => {
    setNewVideolink(e.target.value);
  };

  const onSubmit = e => {
    e.preventDefault();

    if (!newVideolink.trim()) {
      return;
    }

    updateVideo(videoInfo.id, newVideolink);
  };

  return (
    <Container component="main" className={classes.paper}>
      <div className={classes.playerContainer}>
        <ReactPlayer
          width="100%"
          height="100%"
          loop
          config={{
            attributes: {
              style: {
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              },
            },
          }}
          controls
          url={link}
          playing={false}
        />
      </div>
      <form className={classes.form} onSubmit={onSubmit}>
        <TextField
          fullWidth
          InputProps={{ className: classes.input }}
          variant="outlined"
          margin="normal"
          label="Current link"
          value={link}
          disabled
        />
        <TextField
          fullWidth
          InputProps={{ className: classes.input }}
          variant="outlined"
          margin="normal"
          label="New video link"
          name="Video link"
          value={newVideolink}
          onChange={onChange}
        />
        <Button
          fullWidth
          className={classes.submit}
          variant="contained"
          color="primary"
          type="submit"
        >
          Update
        </Button>
      </form>
    </Container>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  updateVideo,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoInfo);
