import React from 'react';

export const Lines = () => (
  <>
    <span
      style={{
        borderTop: '2px solid #808080',
        height: '2px',
        width: '50px',
      }}
    />
    <span
      style={{
        borderTop: '2px solid #808080',
        height: '2px',
        width: '25px',
        marginTop: '2px',
      }}
    />
  </>
);
