import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import Home from './Home/Home';
import ToolBar from './ToolBar/ToolBar';
import Events from './Events/Events';
import Media from './Media/Media';
import Press from './Press/Press';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  toolBarContainer: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    width: '10%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minHeight: '5%',
      marginTop: '64px',
      flexDirection: 'row',
    },
  },
  screenContainer: {
    display: 'flex',
    flex: 1,
    overflowY: 'scroll',
    flexDirection: 'column',
  },
  paper: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Dashboard = ({ isAuthenticated }) => {
  const classes = useStyles();

  if (isAuthenticated) {
    return (
      <div className={classes.mainContainer}>
        <div className={classes.toolBarContainer}>
          <ToolBar />
        </div>
        <div className={classes.screenContainer}>
          <Switch>
            <Route exact path="/dashboard/homePage" component={Home} />
            <Route path="/dashboard/events" component={Events} />
            <Route path="/dashboard/media" component={Media} />
            <Route path="/dashboard/press" component={Press} />
          </Switch>
        </div>
      </div>
    );
  } else {
    return (
      <Paper className={classes.paper} component="main" maxwidth="xs">
        <Typography variant="body1">Login in order to see this page</Typography>
      </Paper>
    );
  }
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
