import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getArticles } from 'src/actions/press';
import Title from 'src/shared/AnimatedTitle';
import LoadingSpinner from 'src/shared/LoadingSpinner';

import ArticleCard from './ArticleCard';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    overflowX: 'hidden',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flex: 1,
    minHeight: '100%',
    paddingTop: '5rem',
    flexDirection: 'column',
    paddingBottom: '4rem',
    overflowX: 'hidden',
  },
  cardsContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    margin: '3rem 15rem',
    [theme.breakpoints.down('md')]: {
      margin: '3rem 1rem',
    },
  },
}));

const Articles = ({ articleList, loading, getArticles }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getArticles();
  }, [getArticles]);

  if (loading)
    return (
      <Container component="main" className={classes.root}>
        <LoadingSpinner />
      </Container>
    );

  const filteredArticlesByLocale = articleList
    .filter(article => article.locale === i18n.language)
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Title subHeader={t('press.titleA')} header={t('press.titleB')} />

      <div className={classes.cardsContainer}>
        <Grid container spacing={2} alignItems="center">
          {filteredArticlesByLocale.map(article => (
            <Grid key={article._id} sm={12} item>
              <ArticleCard articleInfo={article} />
            </Grid>
          ))}
        </Grid>
      </div>
    </Container>
  );
};

const mapStateToProps = state => ({
  articleList: state.press.articleList,
  loading: state.press.loading,
});

const mapDispatchToProps = { getArticles };

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
