import DateFnsUtils from '@date-io/date-fns';
import { Button, MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RiDeleteBinFill as DeleteIcon } from 'react-icons/ri';

import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { removeEvent, updateEvent } from '../../../actions/events';
import Flag from 'react-world-flags';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  image: {
    height: 250,
    maxWidth: 400, // 16:9,
  },
  input: {
    color: '#000',
    backgroundColor: '#fff',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  delete: {
    margin: theme.spacing(3, 0, 2),
    color: '#fff',
    backgroundColor: 'red',
  },
}));

const EventInfo = ({
  eventName,
  eventDate,
  eventAddress,
  eventId,
  eventMembers,
  eventInfoLink,
  eventLocale,
  updateEvent,
  removeEvent,
}) => {
  const classes = useStyles();

  const [eventData, setFormData] = useState({
    name: eventName,
    date: eventDate,
    address: eventAddress,
    id: eventId,
    members: eventMembers.join(','),
    infoLink: eventInfoLink,
  });

  const [lang, setLang] = useState(eventLocale);
  const { name, date, address, members, infoLink } = eventData;

  const onChange = e => {
    setFormData({ ...eventData, [e.target.name]: e.target.value });
  };

  const handleDateChange = date => {
    setFormData({ ...eventData, date });
  };

  const onSubmit = e => {
    e.preventDefault();

    // console.log('New Event Data: ', { ...eventData, locale: lang });
    updateEvent({...eventData, locale: lang});
  };

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <TextField
        required
        fullWidth
        InputProps={{ className: classes.input }}
        variant="outlined"
        margin="normal"
        label="Event Name"
        InputLabelProps={{ className: classes.input }}
        name="name"
        value={name}
        onChange={onChange}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          fullWidth
          format="dd/MM/yyyy"
          className={classes.picker}
          InputLabelProps={{ className: classes.input }}
          variant="outlined"
          margin="normal"
          label="Date"
          name="date"
          value={date}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardTimePicker
          fullWidth
          variant="outlined"
          className={classes.picker}
          InputLabelProps={{ className: classes.input }}
          margin="normal"
          label="Time"
          name="time"
          value={date}
          onChange={handleDateChange}
        />
      </MuiPickersUtilsProvider>

      <TextField
        required
        InputProps={{ className: classes.input }}
        InputLabelProps={{ className: classes.input }}
        variant="outlined"
        margin="normal"
        fullWidth
        label="Address"
        name="address"
        value={address}
        onChange={onChange}
      />
      <TextField
        InputProps={{ className: classes.input }}
        InputLabelProps={{ className: classes.input }}
        variant="outlined"
        margin="normal"
        fullWidth
        label="Event members"
        name="members"
        value={members}
        multiline
        rowsMax={5}
        rows={5}
        maxLength={10}
        onChange={onChange}
      />
      <TextField
        InputProps={{ className: classes.input }}
        InputLabelProps={{ className: classes.input }}
        variant="outlined"
        margin="normal"
        fullWidth
        label="Info Link"
        name="infoLink"
        value={infoLink}
        onChange={onChange}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <h4 style={{ color: '#000' }}>Language</h4>
        <Select value={lang} onChange={e => setLang(e.target.value)}>
          <MenuItem value={'en'}>
            <Flag code={'gb'} height="20" width="20" />
          </MenuItem>
          <MenuItem value={'gr'}>
            <Flag code={'gr'} height="20" width="20" />
          </MenuItem>
        </Select>
      </div>

      <Button
        fullWidth
        className={classes.submit}
        variant="contained"
        color="primary"
        type="submit"
      >
        Save
      </Button>
      <Button
        className={classes.delete}
        endIcon={<DeleteIcon size={25} />}
        onClick={() => removeEvent(eventId)}
      >
        Delete
      </Button>
    </form>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  updateEvent,
  removeEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventInfo);
