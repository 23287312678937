import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import INFO from 'src/info';

import { Lines } from '../Lines';

const useStyles = makeStyles(theme => ({
  contactContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '1rem',
  },
  link: {
    '&:hover': {
      opacity: 0.6
    },
  },
}));

export default function ContactSection() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.contactContainer}>
      <div className={classes.titleContainer}>
        <Typography color="textPrimary" align="center" variant="body1" gutterBottom>
          {t('footer.contact')}
        </Typography>
        <Lines />
      </div>
      <div style={{ margin: '1rem 1rem' }}>
        <Typography color="textPrimary" align="center" variant="body1" gutterBottom>
          {INFO.EMAIL}
        </Typography>
        <Link to="/contact" style={{ textDecoration: 'none' }}>
          <Typography
            align="center"
            color="textPrimary"
            variant="body1"
            className={classes.link}
          >
            {t('footer.email')}
          </Typography>
        </Link>
      </div>
    </div>
  );
}
