import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RiLoginBoxLine as LoginIcon } from 'react-icons/ri';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { loginUser } from 'src/actions/auth';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  input: {
    color: '#000',
    backgroundColor: '#fff',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = ({ loginUser, isAuthenticated, error, loading }) => {
  const classes = useStyles();
  const [formError, setFormError] = useState();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = formData;

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = e => {
    e.preventDefault();
    if (!email || !password) {
      setFormError('Email and password required!');
    } else {
      loginUser(email, password);
      setFormData({
        email: '',
        password: '',
      });
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LoginIcon color="#686d76" size={30} />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            required
            InputProps={{ className: classes.input }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            margin="normal"
            fullWidth
            label="Email Address"
            name="email"
            value={email}
            onChange={onChange}
          />
          <TextField
            required
            InputProps={{ className: classes.input }}
            InputLabelProps={{ className: classes.input }}
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            value={password}
            onChange={onChange}
          />
          {error && (
            <Typography align="center" variant="subtitle1" color="error">
              {error[0].msg}
            </Typography>
          )}
          {formError && (
            <Typography align="center" variant="subtitle1" color="error">
              {formError}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            onClick={onSubmit}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {loading ? (
              <CircularProgress size={20} color="secondary" />
            ) : (
              'Log in'
            )}
          </Button>
        </form>
      </div>
    </Container>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  error: state.auth.error,
});

const mapDispatchToProps = { loginUser };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
