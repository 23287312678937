import { Button, TextField, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';

import { deleteVideo } from 'src/actions/media';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '4rem',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(1),
  },
  playerContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    alignItems: 'center',
  },
  image: {
    maxHeight: 350,
    maxWidth: 400, // 16:9,
  },
  input: {
    color: '#000',
    backgroundColor: '#fff',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  delete: {
    margin: theme.spacing(3, 0, 2),
    color: '#fff',
    backgroundColor: 'red',
  },
}));

const VideoInfo = ({ videoData, deleteVideo }) => {
  const classes = useStyles();

  const { link, _id } = videoData;

  const onSubmit = e => {
    e.preventDefault();

    deleteVideo(_id);
  };

  return (
    <Container component="main" className={classes.paper}>
      <div className={classes.playerContainer}>
        <ReactPlayer
          width="100%"
          height="100%"
          loop
          config={{
            attributes: {
              style: {
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              },
            },
          }}
          controls
          url={link}
          playing={false}
        />
      </div>
      <form className={classes.form} onSubmit={onSubmit}>
        <TextField
          fullWidth
          InputProps={{ className: classes.input }}
          variant="outlined"
          margin="normal"
          label="Current link"
          value={link}
          disabled
        />
        <Button
          className={classes.delete}
          variant="contained"
          color="primary"
          type="submit"
        >
          REMOVE
        </Button>
      </form>
    </Container>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  deleteVideo,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoInfo);
