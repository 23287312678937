import {
  Button,
  Card,
  Container,
  Grid,
  Input,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { RiDeleteBinFill as DeleteIcon } from 'react-icons/ri';
import { connect } from 'react-redux';
import { deleteSlide, uploadSlides } from 'src/actions/carousel';
import LoadingSpinner from 'src/shared/LoadingSpinner';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '4rem',
    marginTop: '2rem',
  },

  media: {
    maxHeight: 240,
    margin: '1rem',
    width: '100%',
    objectFit: 'contain',
  },
  form: {
    marginTop: theme.spacing(3),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    color: '#000',
    backgroundColor: '#fff',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  carouselImagesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: 'red',
    width: '100%',
  },
  delete: {
    margin: theme.spacing(3, 0, 2),
    color: '#fff',
    backgroundColor: 'red',
  },
}));

const Carousel = ({ carouselInfo, deleteSlide, uploadSlides }) => {
  const classes = useStyles();
  const [newImages, setNewImages] = useState();

  const { loading, images } = carouselInfo;

  const onSubmit = e => {
    e.preventDefault();

    uploadSlides(newImages);
  };

  const onImageUpload = event => {
    setNewImages({ files: event.target.files });
  };

  if (loading) return <LoadingSpinner />;

  return (
    <Container component="main" className={classes.root}>
      <Typography variant="h5" align="center" color="secondary">
        Carousel
      </Typography>
      <Grid container spacing={2}>
        {images.length === 0 && (
          <Grid xs={12} item>
            <Typography variant="h5" align="center" color="primary">
              There are no images available...
            </Typography>
          </Grid>
        )}
        {images.map(({ image, _id }) => (
          <Grid key={_id} xs={12} sm={6} lg={4} item>
            <Card className={classes.root}>
              <img alt={image} className={classes.media} src={image} />
              <Button
                className={classes.delete}
                endIcon={<DeleteIcon size={25} />}
                onClick={() => deleteSlide(_id)}
              >
                Remove Image
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>

      <form className={classes.form} onSubmit={onSubmit}>
        <Input
          type="file"
          inputProps={{ multiple: true }}
          accept="image/*"
          onChange={onImageUpload}
        />
        <Button
          className={classes.submit}
          variant="contained"
          color="primary"
          type="submit"
        >
          Add Images
        </Button>
      </form>
    </Container>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = { deleteSlide, uploadSlides };

export default connect(mapStateToProps, mapDispatchToProps)(Carousel);
