import axios from 'axios';

const press = {
  WILL_GET_ARTICLES: 'press/WILL_GET_ARTICLES',
  DID_GET_ARTICLES: 'press/DID_GET_ARTICLES',
  FAILED_TO_GET_ARTICLES: 'press/FAILED_TO_GET_ARTICLES',

  WILL_UPLOAD_ARTICLE: 'press/WILL_UPLOAD_ARTICLE',
  DID_UPLOAD_ARTICLE: 'press/DID_UPLOAD_ARTICLE',
  FAILED_TO_UPLOAD_ARTICLE: 'press/FAILED_TO_UPLOAD_ARTICLE',

  WILL_REMOVE_ARTICLE: 'press/WILL_REMOVE_ARTICLE',
  DID_REMOVE_ARTICLE: 'press/DID_REMOVE_ARTICLE',
  FAILED_TO_REMOVE_ARTICLE: 'press/FAILED_TO_REMOVE_ARTICLE',
};

export default press;

export const getArticles = () => dispatch => {
  dispatch({
    type: press.WILL_GET_ARTICLES,
  });

  axios
    .get('/api/press')
    .then(res => {
      dispatch({
        type: press.DID_GET_ARTICLES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: press.FAILED_TO_GET_ARTICLES,
        payload: err,
      });
    });
};

export const uploadArticle = articleData => dispatch => {
  const { title, date, siteName, description, link, locale } = articleData;

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    title,
    date,
    siteName,
    description,
    link,
    locale,
  });

  dispatch({ type: press.WILL_UPLOAD_ARTICLE });

  axios
    .post('/api/press', body, config)
    .then(res => {
      dispatch({
        type: press.DID_UPLOAD_ARTICLE,
        payload: res.data,
      });
    })
    .catch(err => {
      if (err.response.status === 400) {
        return dispatch({
          type: press.FAILED_TO_UPLOAD_ARTICLE,
          payload: err.response.data,
        });
      }
      dispatch({
        type: press.FAILED_TO_UPLOAD_ARTICLE,
        payload: err,
      });
    });
};

export const removeArticle = id => dispatch => {
  dispatch({
    type: press.WILL_REMOVE_ARTICLE,
  });

  axios
    .delete(`/api/press/${id}`)
    .then(res => {
      dispatch({
        type: press.DID_REMOVE_ARTICLE,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: press.FAILED_TO_UPLOAD_ARTICLE,
        payload: err,
      });
    });
};
