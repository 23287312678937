import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImgsViewer from 'react-images-viewer';
import LoadingSpinner from 'src/shared/LoadingSpinner';
import Title from 'src/shared/Title';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginTop: '5rem',
    flexDirection: 'column',
  },
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '3rem',
  },
  imageContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '3rem',
    padding: '5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
  image: {
    width: '100%',
    maxHeight: '400px',
    height: '300px',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard,
    }),
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.9,
      transform: 'scale(1.03)',
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#fefefe',
  },

  subtitle: {
    fontSize: '14px',
    color: '#fefefe',
    fontWeight: 'bold',
  },

  '@keyframes elevate': {
    '0%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(1.1)',
    },
  },
}));

const ImagesSection = ({ images }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const [currentImage, setCurrentImage] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [showCredit, setShowCredit] = useState();

  const { imagesList, loading } = images;
  const imagesR = imagesList.map(image => ({ src: image.image }));

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Title
        subHeader={t('media.imagesSection.titleA')}
        header={t('media.imagesSection.titleB')}
      />
      {loading && (
        <Container component="main" className={classes.root}>
          <LoadingSpinner />
        </Container>
      )}
      {!loading && (
        <div className={classes.imageContainer}>
          <Grid container spacing={1} alignItems="center">
            {imagesList.map(({ image, _id, credit }, index) => (
              <Grid key={_id} md={6} lg={4} container justify="center" item>
                <div
                  className={classes.image}
                  onClick={() => {
                    setCurrentImage(index);
                    setIsLightboxOpen(true);
                  }}
                  onMouseOver={() => setShowCredit(_id)}
                  onMouseLeave={() => setShowCredit()}
                  style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  {showCredit === _id && (
                    <span
                      style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography className={classes.title} color="primary">
                        OROS Ensemble
                      </Typography>
                      <Typography color="primary" className={classes.subtitle}>
                        {credit && '©' + credit}
                      </Typography>
                    </span>
                  )}
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      <ImgsViewer
        backdropCloseable={true}
        showCloseBtn
        imgs={imagesR}
        isOpen={isLightboxOpen}
        currImg={currentImage}
        onClickPrev={() =>
          setCurrentImage((currentImage - 1) % imagesList.length)
        }
        onClickNext={() =>
          setCurrentImage((currentImage + 1) % imagesList.length)
        }
        onClose={() => setIsLightboxOpen(false)}
      />
    </Container>
  );
};

export default ImagesSection;
