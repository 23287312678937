import {
  Button,
  Card,
  Container,
  Grid,
  Input,
  Typography,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { RiDeleteBinFill as DeleteIcon } from 'react-icons/ri';
import { connect } from 'react-redux';
import { deleteImage, getImages, uploadImages } from 'src/actions/media';
import LoadingSpinner from 'src/shared/LoadingSpinner';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '5rem',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '4rem',
    marginTop: '2rem',
  },
  media: {
    maxHeight: 140,
    margin: '1rem',
    width: '100%',
    objectFit: 'contain',
  },
  form: {
    marginTop: theme.spacing(3),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    color: '#000',
    backgroundColor: '#fff',
  },
  creditsButton: {
    margin: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  delete: {
    margin: theme.spacing(3, 0, 2),
    color: '#fff',
    backgroundColor: 'red',
  },
}));

const Images = ({ images, deleteImage, getImages, uploadImages }) => {
  const classes = useStyles();
  const [newImages, setNewImages] = useState({
    files: null,
    credits: '',
  });
  const [creditsOption, setCreditsOption] = useState(null);

  useEffect(() => {
    getImages();
  }, [getImages]);

  const onSubmit = e => {
    e.preventDefault();

    if (newImages) {
      uploadImages(newImages);
    }

    setNewImages({
      files: null,
      credits: '',
    });
  };

  const onImageUpload = event => {
    setNewImages({ ...newImages, files: event.target.files });
  };

  const onChange = e => {
    setNewImages({
      ...newImages,
      [e.target.name]: e.target.value,
    });
  };

  const { loading, imagesList } = images;
  const { credits } = newImages;

  if (loading)
    return (
      <Container component="main" className={classes.root}>
        <LoadingSpinner />
      </Container>
    );

  return (
    <Container component="main" className={classes.root}>
      <Typography variant="h5" align="center" color="secondary" gutterBottom>
        Your Images
      </Typography>
      <Grid container spacing={2}>
        {imagesList.length === 0 && (
          <Grid xs={12} item>
            <Typography variant="h5" align="center" color="primary">
              You dont have any images yet...
            </Typography>
          </Grid>
        )}
        {imagesList.map(({ image, _id }) => (
          <Grid key={_id} xs={12} sm={6} md={4} item>
            <Card className={classes.card}>
              <img alt={image} className={classes.media} src={image} />
              <Button
                className={classes.delete}
                endIcon={<DeleteIcon size={25} />}
                onClick={() => deleteImage(_id)}
              >
                Remove Image
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>

      <div>
        <Button
          className={classes.creditsButton}
          variant="contained"
          color="primary"
          onClick={() => {
            setCreditsOption(true);
          }}
        >
          Images with credits
        </Button>
        <Button
          className={classes.creditsButton}
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => {
            setCreditsOption(false);
          }}
        >
          Images without credits
        </Button>
      </div>

      {!creditsOption && creditsOption !== null && (
        <form className={classes.form} onSubmit={onSubmit}>
          <Input
            type="file"
            inputProps={{ multiple: true }}
            accept="image/*"
            onChange={onImageUpload}
          />
          <Button
            className={classes.submit}
            variant="contained"
            color="primary"
            type="submit"
          >
            Add Images
          </Button>
        </form>
      )}

      {creditsOption && (
        <form className={classes.form} onSubmit={onSubmit}>
          <Input
            type="file"
            inputProps={{ multiple: true }}
            accept="image/*"
            onChange={onImageUpload}
          />
          <TextField
            required
            InputProps={{ className: classes.input }}
            variant="outlined"
            margin="normal"
            label="Credits"
            name="credits"
            value={credits}
            onChange={onChange}
          />
          <Button
            className={classes.submit}
            variant="contained"
            color="primary"
            type="submit"
          >
            Add Images
          </Button>
        </form>
      )}
    </Container>
  );
};

const mapStateToProps = state => ({
  images: state.media.images,
});

const mapDispatchToProps = {
  getImages,
  deleteImage,
  uploadImages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Images);
