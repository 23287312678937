import { Button, MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { uploadArticle } from 'src/actions/press';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Flag from 'react-world-flags';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  input: {
    color: '#000',
    backgroundColor: '#fff',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const AddArticle = ({ uploadArticle }) => {
  const classes = useStyles();

  const [lang, setLang] = useState('en');
  const [articleData, setArticleData] = useState({
    title: '',
    date: new Date(),
    siteName: '',
    description: '',
    link: '',
  });

  const onChange = e => {
    setArticleData({ ...articleData, [e.target.name]: e.target.value });
  };

  const handleDateChange = date => {
    setArticleData({ ...articleData, date });
  };

  const onSubmit = e => {
    e.preventDefault();

    uploadArticle({ ...articleData, locale: lang });

    setArticleData({
      title: '',
      date: new Date(),
      siteName: '',
      description: '',
      link: '',
    });
  };

  const { title, date, siteName, description, link } = articleData;

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <TextField
        required
        fullWidth
        InputProps={{ className: classes.input }}
        InputLabelProps={{ className: classes.input }}
        variant="outlined"
        margin="normal"
        label="Title"
        name="title"
        value={title}
        onChange={onChange}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          fullWidth
          InputLabelProps={{ className: classes.input }}
          required
          format="dd/MM/yyyy"
          className={classes.picker}
          variant="outlined"
          margin="normal"
          label="Date"
          name="date"
          value={date}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>

      <TextField
        required
        InputProps={{ className: classes.input }}
        InputLabelProps={{ className: classes.input }}
        variant="outlined"
        margin="normal"
        fullWidth
        label="Site name"
        name="siteName"
        value={siteName}
        onChange={onChange}
      />
      <TextField
        required
        InputProps={{ className: classes.input }}
        InputLabelProps={{ className: classes.input }}
        variant="outlined"
        margin="normal"
        fullWidth
        label="Description"
        name="description"
        value={description}
        multiline
        rowsMax={5}
        rows={5}
        maxLength={10}
        onChange={onChange}
      />
      <TextField
        required
        InputProps={{ className: classes.input }}
        InputLabelProps={{ className: classes.input }}
        variant="outlined"
        margin="normal"
        fullWidth
        label="Link"
        name="link"
        value={link}
        onChange={onChange}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <h4 style={{ color: '#000' }}>Language</h4>
        <Select value={lang} onChange={e => setLang(e.target.value)}>
          <MenuItem value={'en'}>
            <Flag code={'gb'} height="20" width="20" />
          </MenuItem>
          <MenuItem value={'gr'}>
            <Flag code={'gr'} height="20" width="20" />
          </MenuItem>
        </Select>
      </div>

      <Button
        fullWidth
        className={classes.submit}
        variant="contained"
        color="primary"
        type="submit"
      >
        Add Article
      </Button>
    </form>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  uploadArticle,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddArticle);
