import { Container, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getSlides } from 'src/actions/carousel';

import WelcomeVideos from './WelcomeVideos';
import Carousel from './Carousel';

const useStyles = makeStyles(theme => ({
  paper: {
    height: '100%',
    paddingTop: '100px',
  },
}));

const Home = ({ carouselInfo, getSlides }) => {
  const classes = useStyles();

  useEffect(() => {
    getSlides();
  }, [getSlides]);

  return (
    <Container component="main" className={classes.paper}>
      <Typography
        color="secondary"
        align="center"
        component="h1"
        gutterBottom
        variant="h4"
      >
        Modify Home Page
      </Typography>
      <Carousel carouselInfo={carouselInfo} />
      <Divider variant="middle"  />
      <WelcomeVideos />
    </Container>
  );
};

const mapStateToProps = state => ({
  carouselInfo: state.carousel,
});

const mapDispatchToProps = { getSlides };

export default connect(mapStateToProps, mapDispatchToProps)(Home);
