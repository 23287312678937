import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSlides } from 'src/actions/carousel';
import Carousel from 'src/shared/Carousel';
import LoadingSpinner from 'src/shared/LoadingSpinner';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const LandingCarousel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSlides());
  }, [dispatch]);

  const carouselSlides = useSelector(state => state.carousel);

  const { loading, images } = carouselSlides;

  if (loading) return <LoadingSpinner />;

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Carousel images={images} />
    </Container>
  );
};

export default LandingCarousel;
