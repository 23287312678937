import contact from 'src/actions/contact';

const initialState = {
  sending: false,
  status: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case contact.WILL_SEND_MESSAGE:
      return {
        ...state,
        sending: true,
      };

    case contact.DID_SEND_MESSAGE:
      return {
        ...state,
        sending: false,
        status: action.payload,
      };

    case contact.FAILED_TO_SEND_MESSAGE:
      return {
        ...state,
        sending: false,
        status: action.payload,
      };

    default:
      return state;
  }
};
