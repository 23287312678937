import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Slider from 'react-slick';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '600px',
    position: 'relative',
    marginTop: '5rem',
  },
  slider: {
    width: '100%',
    height: '100%',
  },
  slide: {
    objectPosition: 'center',
    objectFit: 'cover',
    height: '600px',
    width: '100%',
  },
  overlay: {
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.4)',
    pointerEvents: 'none',
  },
}));

export default function Carousel({ images }) {
  const classes = useStyles();

  if (images.length === 0 || !images) {
    return null;
  }

  const slideSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    pauseOnHover: false,
  };

  return (
    <Container maxWidth="lg" disableGutters className={classes.container}>
      <Slider {...slideSettings} className={classes.slider}>
        {images.map(({ image, _id }, index) => {
          return (
            <div
              style={{
                width: '100%',
                height: '100%',
              }}
              key={_id}
            >
              <img alt="The Ensemble" src={image} className={classes.slide} />
              {index !== 0 && <div className={classes.overlay} />}
            </div>
          );
        })}
      </Slider>
    </Container>
  );
}
