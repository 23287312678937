import {
  Button,
  Card,
  Container,
  Grid,
  Input,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { RiDeleteBinFill as DeleteIcon } from 'react-icons/ri';
import { connect } from 'react-redux';
import { deleteLogo, getLogos, uploadLogos } from 'src/actions/media';
import LoadingSpinner from 'src/shared/LoadingSpinner';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '5rem',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '4rem',
    marginTop: '2rem',
  },
  media: {
    maxHeight: 140,
    margin: '1rem',
    width: '100%',
    objectFit: 'contain',
  },
  form: {
    marginTop: theme.spacing(3),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    color: '#000',
    backgroundColor: '#fff',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  delete: {
    margin: theme.spacing(3, 0, 2),
    color: '#fff',
    backgroundColor: 'red',
  },
}));

const Logos = ({ logos, deleteLogo, getLogos, uploadLogos }) => {
  const classes = useStyles();
  const [newLogos, setNewLogos] = useState();

  useEffect(() => {
    getLogos();
  }, [getLogos]);

  const onSubmit = e => {
    e.preventDefault();

    if (newLogos) {
      uploadLogos(newLogos);
    }

    setNewLogos();
  };

  const onLogoUpload = event => {
    setNewLogos({ files: event.target.files });
  };

  const { loading, logoList } = logos;

  if (loading)
    return (
      <Container component="main" className={classes.root}>
        <LoadingSpinner />
      </Container>
    );

  return (
    <Container component="main" className={classes.root}>
      <Typography variant="h5" align="center" color="secondary" gutterBottom>
        Your Logos
      </Typography>
      <Grid container spacing={2}>
        {logoList.length === 0 && (
          <Grid xs={12} item>
            <Typography variant="h5" align="center" color="primary">
              You dont have any logos yet...
            </Typography>
          </Grid>
        )}
        {logoList.map(({ image, _id }) => (
          <Grid key={_id} xs={12} sm={6} md={4} item>
            <Card className={classes.card}>
              <img alt={image} className={classes.media} src={image} />
              <Button
                className={classes.delete}
                endIcon={<DeleteIcon size={25} />}
                onClick={() => deleteLogo(_id)}
              >
                Remove Logo
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>

      <form className={classes.form} onSubmit={onSubmit}>
        <Input
          InputLabelProps={{className: classes.input}}
          type="file"
          inputProps={{ multiple: true }}
          accept="image/*"
          onChange={onLogoUpload}
        />
        <Button
          className={classes.submit}
          variant="contained"
          color="primary"
          type="submit"
        >
          Add Logos
        </Button>
      </form>
    </Container>
  );
};

const mapStateToProps = state => ({
  logos: state.media.logos,
});

const mapDispatchToProps = {
  deleteLogo,
  getLogos,
  uploadLogos,
};

export default connect(mapStateToProps, mapDispatchToProps)(Logos);
