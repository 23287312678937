import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Link as MLink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Lines } from '../Lines';
import INFO from 'src/info';

import {
  RiInstagramFill as Instagram,
  RiFacebookBoxFill as Facebook,
  RiSoundcloudFill as SoundCloud,
  RiYoutubeFill as YouTube,
  RiSpotifyFill as Spotify,
  RiAppleFill as Apple,
} from 'react-icons/ri';

const useStyles = makeStyles(theme => ({
  logoAndSocialContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '2rem',
  },
  socialIcons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30%',
    paddingBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      marginTop: '1rem',
      paddingBottom: '1rem',
      flexWrap: 'wrap',
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '38px',
    height: '38px',
  },
  icon: {
    width: '30px',
    height: '30px',
    color: theme.palette.text.primary,
    margin: '5px',
  },
  link: {
    fontSize: '26px',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
}));

export default function SocialSection() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.logoAndSocialContainer}>
      <div className={classes.titleContainer}>
        <Typography variant="body1" gutterBottom color="textPrimary">
          {t('footer.social')}
        </Typography>
        <Lines />
      </div>
      <div className={classes.socialIcons}>
        <MLink
          href={INFO.YOUTUBE}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.iconContainer}
        >
          <YouTube className={`${classes.icon} ${classes.link}`} />
        </MLink>

        <MLink
          href={INFO.FACEBOOK}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.iconContainer}
        >
          <Facebook className={`${classes.icon} ${classes.link}`} />
        </MLink>
        <MLink
          href={INFO.INSTA}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.iconContainer}
        >
          <Instagram className={`${classes.icon} ${classes.link}`} />
        </MLink>
        <MLink
          href={INFO.SOUND_CLOUD}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.iconContainer}
        >
          <SoundCloud className={`${classes.icon} ${classes.link}`} />
        </MLink>
        <MLink
          href={INFO.SPOTIFY}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.iconContainer}
        >
          <Spotify className={`${classes.icon} ${classes.link}`} />
        </MLink>
        <MLink
          href={INFO.APPLE_MUSIC}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.iconContainer}
        >
          <Apple style={{paddingBottom: '5px'}}  className={`${classes.icon} ${classes.link}`} />
        </MLink>
      </div>
    </div>
  );
}
