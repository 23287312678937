import { Grid, Link as MLink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import INFO from 'src/info';
import { NiarxosLogo } from './assets/NiarxosLogo';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5rem 5%',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '1rem',
  },

  logo: {
    display: 'flex ',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem',
  },

  link: {
    '&:hover': {
      color: '#fff',
    },
  },
}));

export default function Logos() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container alignItems="center" justify="center">
        <Grid item sm={4} className={classes.logo}>
          <MLink href={INFO.VAMVAKOU} target="_blank" rel="noopener noreferrer">
            <img
              style={{ paddingBottom: '2rem' }}
              alt="Vamvakou logo"
              src="https://www.vamvakourevival.org/wp-content/themes/vamvakou/dist/assets/images/vamvakou-revival-logo.svg"
            />
          </MLink>
        </Grid>

        <Grid item sm={4} className={classes.logo}>
          <MLink
            href={INFO.NATIONAL_OPERA}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="National Opera logo"
              style={{
                width: '200px',
              }}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/GNO-logo.svg/1024px-GNO-logo.svg.png"
            />
          </MLink>
        </Grid>
        <Grid item sm={4} className={classes.logo}>
          <MLink href={INFO.NIARXOS} target="_blank" rel="noopener noreferrer">
            <NiarxosLogo />
          </MLink>
        </Grid>
      </Grid>
    </div>
  );
}
