import welcome from '../actions/welcome';

const initialState = {
  loading: true,
  videos: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case welcome.WILL_GET_VIDEOS:
    case welcome.WILL_ADD_VIDEO:
    case welcome.WILL_UPDATE_VIDEO:
      return {
        ...state,
        loading: true,
      };

    case welcome.DID_GET_VIDEOS:
      return {
        ...state,
        loading: false,
        videos: action.payload,
      };

    case welcome.FAILED_GET_VIDEOS:
    case welcome.FAILED_TO_ADD_VIDEO:
    case welcome.FAILED_TO_UPDATE_VIDEO:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case welcome.DID_ADD_VIDEO:
      return {
        ...state,
        loading: false,
        videos: [...state.videos, action.payload],
      };

    case welcome.DID_UPDATE_VIDEO:
      return {
        ...state,
        loading: false,
        updatedVideo: action.payload,
      };

    default:
      return state;
  }
};
