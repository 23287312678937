import {
  CardContent,
  Divider,
  Link as MLink,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef } from 'react';
import { Lines } from 'src/shared/Lines';
import { convertDate } from 'src/utils/dateConverter';

import gsap from 'gsap';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    maxWidth: '50%',
    minWidth: '450px',
    marginTop: '1rem',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
      flexDirection: 'column',
    },
  },
  eventDate: {
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: '1rem',
  },
  placeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dateText: {
    fontWeight: '700',
  },
  eventInfo: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-around',
    },
  },
  titleText: {
    fontWeight: '700',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: '1rem',
  },
  link: {
    '&:hover': {
      opacity: 0.6,
    },
  },
}));

export default function EventCard({
  eventName,
  eventDate,
  eventAddress,
  eventMembers,
  eventInfoLink,
}) {
  const classes = useStyles();
  const card = useRef(null);

  useEffect(() => {
    if (card) {
      gsap.from(card.current, {
        x: 100,
        duration: 1,
        autoAlpha: 0,
        ease: 'none',
        scrollTrigger: {
          trigger: card.current,
          start: 'top bottom-=100',
          toggleActions: 'play none none reverse',
        },
      });
    }
  }, [card]);

  return (
    <div ref={card} className={classes.card}>
      <div className={classes.details}>
        <CardContent className={classes.eventDate}>
          <Typography
            variant="body1"
            color="textPrimary"
            gutterBottom
            className={classes.dateText}
          >
            {convertDate(eventDate, 'dd/MM/yyyy')}
          </Typography>
          <Lines />
        </CardContent>
      </div>
      <Divider variant="inset" orientation="vertical" flexItem={true} />
      <div className={classes.details}>
        <CardContent className={classes.eventInfo}>
          <Typography variant="body1" className={classes.titleText}>
            {eventName}
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {eventAddress}, {convertDate(eventDate, 'HH:mm')}
          </Typography>
          <Divider variant="fullWidth" style={{ height: '1rem' }} />
          <Typography variant="body1" color="textPrimary">
            {eventMembers.join(',')}
          </Typography>
          <MLink
            href={eventInfoLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.link}
            >
              {'More Info'}
            </Typography>
          </MLink>
        </CardContent>
      </div>
    </div>
  );
}
