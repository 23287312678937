import DateFnsUtils from '@date-io/date-fns';
import { Button, MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Flag from 'react-world-flags';

import { addEvent } from '../../../actions/events';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  input: {
    color: '#000',
    backgroundColor: '#fff',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const AddEvent = ({ addEvent }) => {
  const classes = useStyles();
  const [lang, setLang] = useState('en');

  const [eventData, setEventData] = useState({
    name: '',
    date: new Date(),
    address: '',
    members: '',
    infoLink: '',
  });

  const { name, date, address, infoLink, members } = eventData;

  const onChange = e => {
    setEventData({ ...eventData, [e.target.name]: e.target.value });
  };

  const handleDateChange = date => {
    setEventData({ ...eventData, date });
  };

  const onSubmit = e => {
    e.preventDefault();

    addEvent({
      ...eventData,
      locale: lang,
    });

    setEventData({
      name: '',
      date: '',
      address: '',
      members: '',
      infoLink: '',
    });

    setLang('en');
  };

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <TextField
        required
        fullWidth
        InputProps={{ className: classes.input }}
        InputLabelProps={{ className: classes.input }}
        variant="outlined"
        margin="normal"
        label="Name"
        name="name"
        value={name}
        onChange={onChange}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          fullWidth
          required
          InputLabelProps={{ className: classes.input }}
          format="dd/MM/yyyy"
          className={classes.picker}
          variant="outlined"
          margin="normal"
          label="Date"
          name="date"
          value={date}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardTimePicker
          fullWidth
          required
          InputLabelProps={{ className: classes.input }}
          variant="outlined"
          className={classes.picker}
          margin="normal"
          label="Time"
          name="time"
          value={date}
          onChange={handleDateChange}
        />
      </MuiPickersUtilsProvider>
      <TextField
        required
        InputProps={{ className: classes.input }}
        InputLabelProps={{ className: classes.input }}
        variant="outlined"
        margin="normal"
        fullWidth
        label="Address"
        name="address"
        value={address}
        onChange={onChange}
      />
      <TextField
        required
        InputProps={{ className: classes.input }}
        InputLabelProps={{ className: classes.input }}
        variant="outlined"
        margin="normal"
        fullWidth
        label="Members"
        name="members"
        value={members}
        multiline
        rowsMax={5}
        rows={5}
        maxLength={10}
        onChange={onChange}
      />
      <TextField
        required
        InputProps={{ className: classes.input }}
        InputLabelProps={{ className: classes.input }}
        variant="outlined"
        margin="normal"
        fullWidth
        label="Info Link"
        name="infoLink"
        value={infoLink}
        onChange={onChange}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <h4 style={{ color: '#000' }}>Language</h4>
        <Select value={lang} onChange={e => setLang(e.target.value)}>
          <MenuItem value={'en'}>
            <Flag code={'gb'} height="20" width="20" />
          </MenuItem>
          <MenuItem value={'gr'}>
            <Flag code={'gr'} height="20" width="20" />
          </MenuItem>
        </Select>
      </div>

      <Button
        fullWidth
        className={classes.submit}
        variant="contained"
        color="primary"
        type="submit"
      >
        Add Event
      </Button>
    </form>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  addEvent,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddEvent);
