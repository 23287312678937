import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Dashboard from 'src/components/Dashboard/Dashboard';

import { loadUser } from './actions/auth';
import store from './store';
import SideDrawer from './components/NavBar/SideDrawer';
import setAuthToken from './utils/setAuthToken';

import NavBar from './components/NavBar/NavBar';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import HomePageContainer from './components/HomePage/HomePageContainer';
import AboutUsContainer from './components/AboutUs/AboutUsContainer';
import Contact from './components/Contact/Contact';
import MediaContainer from './components/Media/MediaContainer';
import Concerts from './components/Concerts/Concerts';
import Articles from './components/Press/Articles';
import ScrollToTop from './shared/ScrollToTop';
import Footer from './shared/Footer/Footer';
import Sound from './components/Sound/Sound';

function App() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  useEffect(() => {
    setAuthToken(localStorage.token);
    store.dispatch(loadUser());
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <Router>
      <ScrollToTop />
      <NavBar toggleDrawer={toggleDrawer} />
      <SideDrawer toggleDrawer={toggleDrawer} isOpen={isDrawerOpen} />
      <Route exact path="/" component={HomePageContainer} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/contact" component={Contact} />
      <Route path="/dashboard" component={Dashboard} />
      <Route exact path="/us" component={AboutUsContainer} />
      <Route exact path="/media" component={MediaContainer} />
      <Route exact path="/concerts" component={Concerts} />
      <Route exact path="/press" component={Articles} />
      <Route exact path="/sound" component={Sound} />
      <Footer />
    </Router>
  );
}

export default App;
