import { Container, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { getVideos } from 'src/actions/welcome';
import Title from 'src/shared/Title';

const Welcome = ({ scrollRef, getVideos, videos }) => {
  const { t } = useTranslation();

  useEffect(() => {
    getVideos();
  }, [getVideos]);

  return (
    <Container maxWidth="lg" ref={scrollRef} style={{ paddingTop: '4rem' }}>
      <Title subHeader={t('homePage.welcome.title')} link={'/us'} />
      <Grid
        container
        spacing={3}
        alignItems="center"
        style={{ marginTop: '3rem' }}
      >
        {videos.map(({ link, _id }) => (
          <Grid item xs={12} md={6} lg={6} key={_id}>
            <ReactPlayer
              width="100%"
              loop
              controls
              url={link}
              playing={false}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

const mapStateToProps = state => ({
  videos: state.welcome.videos,
});

const mapDispatchToProps = {
  getVideos,
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
